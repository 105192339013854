export const PACKAGE_ACCESS_API_NAMES = {
  Full_Access: "Full_Access",
  Limited_Access: "Limited_Access",
  No_Access: "No_Access",
  Product_3D_designer: "Product_3D_designer",
  Product_3D_Designer_Export: "Product_3D_Designer_Export",
  Product_3D_Designer_Import: "Product_3D_Designer_Import",
  Product_Map_layers: "Product_Map_layers",
  Product_Projects: "Product_Projects",
  Product_Reports: "Product_Reports",
  Product_Search: "Product_Search",
  Product_Tags: "Product_Tags",
  Widget_Travel_time: "Widget_Travel_time",
  MapLayers_Filter: "MapLayers_Filter",
  Widget_ChangeAllBuildingColor: "Widget_ChangeAllBuildingColor",
  Widget_3DVegetation: "Widget_3DVegetation",
  Cadastre_LandRegister_Current: "Cadastre_LandRegister_Current",
  Cadastre_LandRegister_Historical: "Cadastre_LandRegister_Historical",
  Api_Customer_Access: "Api_Customer_Access",
  Product_3D_designer_terraformer: "Product_3D_designer_terraformer",
  Widget_3D_terra_measure: "Widget_3D_terra_measure",
  Link_PropCloud_DirectAccess: "Link_PropCloud_DirectAccess"
} as const

export const PACKAGE_ACCESS_API_NAMES_MATCHING = {
  Matching: "Matching",
  Matching_Category_Property: "Matching_Category_Property",
  Matching_Category_Building: "Matching_Category_Building",
  Matching_Category_Location: "Matching_Category_Location",
  Matching_Category_Plots: "Matching_Category_Plots",
  Matching_Category_Certifications: "Matching_Category_Certifications",
  Matching_Category_Lessees: "Matching_Category_Lessees",
  Matching_Category_Occupancies: "Matching_Category_Occupancies",
  Matching_Category_Owners: "Matching_Category_Owners",
  Matching_Category_Renters: "Matching_Category_Renters",
  Matching_Category_LandUseMasterPlan: "Matching_Category_LandUseMasterPlan"
} as const

export const PACKAGE_PROMPT_STRATEGY = {
  /** will disable the feature with an overlay and promote the user to upgrade */
  disableAndPromote: "disableAndPromote",
  /** will let the user use the feature, but prompt the user to upgrade for more info */
  enableAndPromote: "showUpgradeInfo",
  /** render content as normal */
  none: "none"
} as const

export type PackagePromptStrategy
  = typeof PACKAGE_PROMPT_STRATEGY[keyof typeof PACKAGE_PROMPT_STRATEGY]

export const CUSTOM_PACKAGE_CODES = {
  none: 1,
  package: 2,
  custom: 3
}
export const CUSTOM_PACKAGE_PROMPT_TITLE = "Tilleggsprodukt"
export const CUSTOM_PACKAGE_PROMPT_ICON = "https://assets.placepoint.no/images/icons/package-custom-icon.svg"
export const CUSTOM_PACKAGE_PROMPT_TEXT = "Kartlaget er tilgjengelig som et tilleggsprodukt. Ta kontakt med Placepoint for mer informasjon."
