import Sidebar from "@/v1-ui/Sidebar"
import { useAppContext } from "@/v1-console/app/context/AppContextProvider"
import { trackAppSidebarNavigated } from "@/v1-console/app/app.tracking"
import { useProjectPanelOpener }
  from "@/v1-console/project/project.constants.utils"
import AppSidebarItemsProjectMatching
  from "@/v1-console/app/sidebar/items/AppSidebarItemsProjectMatching"

function AppSidebarItemsProject() {
  const opener = useProjectPanelOpener()
  const { currentUser } = useAppContext()
  const hasActiveProject = Boolean(currentUser?.ActiveProjectId)

  return (
    <>
      <Sidebar.Item
        isDisabled={!hasActiveProject}
        className="app-sidebar__to-activities"
        iconSrc="pulse"
        isActive={opener.getIsActive("activities")}
        onClick={() => {
          opener.open("activities")
          trackAppSidebarNavigated("Activities", {
            issueId: 6505,
            sprintNo: 24
          })
        }}
      >
        Aktiviteter
      </Sidebar.Item>

      <AppSidebarItemsProjectMatching
        hasActiveProject={hasActiveProject}
      />

      <Sidebar.Item
        isDisabled={!hasActiveProject}
        className="app-sidebar__to-objects"
        iconSrc="building"
        isActive={opener.getIsActive("objects")}
        onClick={() => {
          opener.open("objects")
          trackAppSidebarNavigated("Objects", {
            issueId: 6505,
            sprintNo: 24
          })
        }}
      >
        Eiendommer
      </Sidebar.Item>

      <Sidebar.Item
        isDisabled={!hasActiveProject}
        className="app-sidebar__to-areas"
        iconSrc="tool-marquee"
        isActive={opener.getIsActive("areas")}
        onClick={() => {
          opener.open("areas")
          trackAppSidebarNavigated("Areas", {
            issueId: 6505,
            sprintNo: 24
          })
        }}
      >
        Områder
      </Sidebar.Item>

      <Sidebar.Item
        isDisabled={!hasActiveProject}
        className="app-sidebar__to-monitor"
        iconSrc="tachometer"
        isActive={opener.getIsActive("monitor")}
        onClick={() => {
          opener.open("monitor")
          trackAppSidebarNavigated("Monitor", {
            issueId: 6505,
            sprintNo: 24
          })
        }}
      >
        Overvåking
      </Sidebar.Item>
    </>
  )
}

export default AppSidebarItemsProject
