import type { ReadFieldFunction, ToReferenceFunction }
  from "@apollo/client/cache/core/types/common"
import type {
  DocumentNode,
  MutationHookOptions,
  TypedDocumentNode,
  OperationVariables,
  MutationTuple,
  Reference,
  MutationFunctionOptions,
  ServerError
} from "@apollo/client"
import { useMutation } from "@apollo/client"
import { randomId } from "@/v1-ui/utils"
import { useDebounce } from "@/v1-console/components/component.utils"

export function getIsAuthServerError(e: ServerError) {
  return e.statusCode === 401 || e.statusCode === 403
}

type Item = Record<string, unknown> & {
  Id?: number | string,
  Code?: number | string
}

export const updateOrPushObjects = <T = any>(
  newItem: (T & { Id: number | string }),
  items: (T & { Id: number | string })[]
): T[] => {
  if(!newItem) return items
  return items.some((obj) => newItem.Id === obj.Id)
    ? items.map((obj) => newItem.Id === obj.Id ? newItem : obj)
    : [ ...items, newItem ]
}

export const removeObjectById = <T = any>(
  id: number | string,
  items: (T & { Id: number | string })[]
): T[] => {
  if(!id) return items
  return items.filter(({ Id }) => id !== Id)
}

type Deps = {
  isReference(obj: any): obj is Reference,
  readField: ReadFieldFunction,
  toReference: ToReferenceFunction
}

export const upsertByRef = (
  newItem, //: Item | Reference,
  existing: Reference | Reference[],
  deps: Deps,
  typeName?: string,
  key = "Id"
): Reference | Reference[] => {
  if(!newItem || !Array.isArray(existing)) {
    return existing
  }
  const isExisting = existing.some(
    (ref) => newItem[key] === deps.readField(key, ref)
  )
  if(isExisting) return existing
  return [
    deps.isReference(newItem)
      ? newItem
      : deps.toReference({
        id: newItem.Id,
        __typename: typeName
      }),
    ...existing
  ]
}

export const removeByRef = (
  Id: number | string,
  items: Item[],
  readField: any, // ReadFieldFunction,
  key = "Id"
): Item[] => {
  if(!Id) return items
  return items.filter((ref) => Id !== readField(key, ref))
}
export const removeMultipleByRef = (
  Ids: number[] | string[],
  items: Item[],
  readField: ReadFieldFunction,
  key = "Id"
): Item[] => {
  if(Ids.length === 0) return items
  return items.filter((ref: Item) => !Ids.includes(readField(key, ref)))
}

// <T = Record<string, unknown>>
export const createOptimisticResponse = (
  typename: string,
  stub,
  fields,
  pk = "Id"
) => {
  return {
    __typename: "Mutation",
    [typename]: {
      __typename: typename,
      ...stub,
      ...fields,
      [pk]: fields[pk] || randomId()
    }
  }
}

const defaultDebounceConfig = {
  timeout: 350,
  trailing: true,
  leading: false
}

export const useMutationDebounced = <
  TData = any,
  TVariables = OperationVariables
>(
  mutation: DocumentNode | TypedDocumentNode<TData, Record<string, TVariables>>,
  options?: MutationHookOptions<TData, Record<string, TVariables>>,
  debounceConfig = defaultDebounceConfig
): MutationTuple<TData, TVariables> => {
  const { timeout, trailing, leading } = debounceConfig
  const [ exec, res ] = useMutation(mutation, options)

  const upsert = useDebounce((options?: MutationFunctionOptions<TData, Record<string, TVariables>>) => {
    exec(options)
  }, timeout, {
    trailing,
    leading
  })

  return [
    upsert,
    res
  ]
}
