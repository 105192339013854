import "./dialog2.css"

import Dialog2 from "./Dialog2"
import Dialog2Header from "./Dialog2Header"
import Dialog2HeaderActions from "./Dialog2HeaderActions"
import Dialog2Body from "./Dialog2Body"
import Dialog2Footer from "./Dialog2Footer"
import Dialog2Backdrop from "./Dialog2Backdrop"
import Dialog2CloseButton from "./Dialog2CloseButton"
import Dialog2FullscreenButton from "./Dialog2FullscreenButton"

export type { Dialog2Props } from "./Dialog2"
export type { Dialog2HeaderProps } from "./Dialog2Header"
export type { Dialog2HeaderActionsProps } from "./Dialog2HeaderActions"
export type { Dialog2BodyProps } from "./Dialog2Body"
export type { Dialog2FooterProps } from "./Dialog2Footer"
export type { Dialog2BackdropProps } from "./Dialog2Backdrop"
export type { Dialog2CloseButtonProps } from "./Dialog2CloseButton"
export type { Dialog2FullscreenButtonProps } from "./Dialog2FullscreenButton"

const components = {
  Header: Object.assign(Dialog2Header, {
    Actions: Dialog2HeaderActions
  }),
  Body: Dialog2Body,
  Footer: Dialog2Footer,
  Backdrop: Dialog2Backdrop,
  CloseButton: Dialog2CloseButton,
  FullscreenButton: Dialog2FullscreenButton
}

export default Object.assign(Dialog2, components)
