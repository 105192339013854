import type { ComponentProps } from "react"
import cn from "@/v1-ui/utils/utils.cn"
import useLocalStorage from "@/v1-ui/utils/utils.localStorage"
import Button from "@/v1-ui/Button"
import { SidebarContext } from "./sidebar.context"

export type SidebarProps = ComponentProps<"ol">

function Sidebar(props: SidebarProps) {
  const { className, children, ...olProps } = props
  const isMiniStorage = useLocalStorage("sidebar-isMini", false)
  const isMini = isMiniStorage.value

  return (
    <SidebarContext.Provider value={{ isMini }}>
      <ol
        className={cn("sidebar", className, {
          "is-mini": isMini
        })}
        {...olProps}
      >
        {children}

        <Button
          small
          className="sidebar-mini-btn"
          iconSrc={isMini
            ? "angle-small-right"
            : "angle-small-left"
          }
          onClick={() => {
            isMiniStorage.setItem(!isMini)
          }}
        />
      </ol>
    </SidebarContext.Provider>
  )
}

export default Sidebar
