import cn from "@/v1-ui/utils/utils.cn"
import { TooltipButton, type TooltipButtonProps } from "@/v1-ui/Button"
import { useFrameContext } from "@/v1-ui/Frame/frame.context"

export type FrameMinimizeButtonProps = TooltipButtonProps

function FrameMinimizeButton(props: FrameMinimizeButtonProps) {
  const { className, onClick, ...tooltipButtonProps } = props
  const { onMinimize, isMinimize } = useFrameContext()
  return (
    <TooltipButton
      containerClassName={cn("frame-minimize-button", className)}
      popoverClassName="z-20"
      content={isMinimize
        ? "Utvid"
        : "Minimer"
      }
      iconSrc={isMinimize
        ? "angle-right"
        : "angle-left"
      }
      onClick={(e) => {
        if(onClick) onClick(e)
        onMinimize(!isMinimize)
      }}
      {...tooltipButtonProps}
    />
  )
}

export default FrameMinimizeButton
