import type { FieldPolicy, Reference } from "@apollo/client"

type Res = {
  next: Reference[],
  prev: Reference[]
}

export const getActivitiesByProjectByNextPrev: FieldPolicy = {
  keyArgs(args) {
    const { projectId } = args.input
    return `${projectId}`
  },
  merge(existing: Res, incoming: Res) {
    if(!existing) return incoming

    // @todo: split next / prev request paylaods when paginating,
    // so we don't have to filter out noisy data

    const next = [ ...existing.next ]
    const nextRefs = new Set(next.map(({ __ref }) => __ref))
    for(const n of incoming.next) {
      if(nextRefs.has(n.__ref)) continue
      next.unshift(n)
    }

    const prev = [ ...existing.prev ]
    const prevRefs = new Set(prev.map(({ __ref }) => __ref))
    for(const n of incoming.prev) {
      if(prevRefs.has(n.__ref)) continue
      prev.push(n)
    }

    return {
      next,
      prev
    }

    // return {
    //   next: [
    //     ...incoming.next,
    //     ...existing.next
    //   ],
    //   prev: [
    //     ...existing.prev,
    //     ...incoming.prev
    //   ]
    // }
  }
}
