import type { Obj } from "@/v1-common/types"
import config from "@/v1-console/config"
import loadSegment from "@/v1-console/analytics/analytics.segment"
import userpilot, { loadUserpilot }
  from "@/v1-console/analytics/analytics.userpilot"
import type { CurrentUserFragment }
  from "@/v1-console/currentUser/__types__/currentUser.fragment"

if(config.isTrackingEnabled) {
  loadSegment(config.segmentKey)
  loadUserpilot(config.userpilotKey)
}

type State = {
  currentUser: CurrentUserFragment
}

const state: State = {
  currentUser: null
}

type Properties = Obj & {
  userId?: string,
  groupId?: string
}

const DEFAULT_INPUT = {
  properties: {} as Properties,
  options: {
    context: {}
  } as SegmentAnalytics.SegmentOpts
}

function getPropsAndOptionsWithDefaults(input = DEFAULT_INPUT) {
  const properties: Properties = {
    ...input.properties
  }

  const options = {
    ...input.options,
    context: {
      groupId: null,
      userId: null,
      app: {
        name: "placepoint-console",
        version: config.version,
        env: config.envName
      },
      ...input.options?.context
    }
  }

  if(state.currentUser) {
    const userTenant = state.currentUser.UserTenants?.[0]
    let groupId = userTenant?.Tenant?.ExternalCustomerId
    let userId = String(state.currentUser.Id)

    if(state.currentUser?.Traits) {
      const { user, group } = state.currentUser.Traits
      if(user) userId = user.id
      if(group) groupId = group.id
    }

    properties.userId = userId
    properties.groupId = groupId
    options.context.groupId = groupId
    options.context.userId = userId
  }

  return {
    properties,
    options
  }
}

export type TrackProperties = Properties & {
  issueId: number,
  sprintNo: number,
  latestIssueId?: number,
  latestSprintNo?: number
}

export function track(
  event: string,
  properties: TrackProperties,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void
) {
  if(!config.isTrackingEnabled || !window.analytics?.track) return
  const p = getPropsAndOptionsWithDefaults({ properties, options })
  userpilot.track(event, p.properties)
  return window.analytics.track(event, p.properties, p.options, callback)
}

export function group(
  groupId: string,
  properties?: Properties,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void
) {
  if(!config.isTrackingEnabled || !window.analytics?.group) return
  const p = getPropsAndOptionsWithDefaults({ properties, options })
  return window.analytics.group(groupId, p.properties, p.options, callback)
}

export function page(
  category: string,
  name: string,
  properties: TrackProperties,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void
) {
  if(!config.isTrackingEnabled || !window.analytics?.page) return
  const p = getPropsAndOptionsWithDefaults({ properties, options })
  userpilot.reload()
  return window.analytics.page(category, name, p.properties, p.options, callback)
}

export function identify(currentUser: CurrentUserFragment) {
  if(!config.isTrackingEnabled) return
  state.currentUser = { ...currentUser }

  const userTraits = state.currentUser.Traits?.user
  const groupTraits = state.currentUser.Traits?.group

  const p = getPropsAndOptionsWithDefaults({
    properties: {
      email: state.currentUser.Email,
      firstName: state.currentUser.FirstName,
      lastName: state.currentUser.LastName,
      ...userTraits
    },
    options: {}
  })

  userpilot.identify(p.properties.userId, p.properties)

  window.analytics.identify(p.properties.userId, p.properties, p.options)
  if(p.properties.groupId) {
    window.analytics.group(p.properties.groupId, groupTraits)
  }
}
