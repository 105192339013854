import type { ComponentProps } from "react"
import cn from "@/v1-ui/utils/utils.cn"
import { DISPLAY_SIZE, type DisplaySize } from "@/v1-ui/constants"

export type BadgeProps = ComponentProps<"span"> & {
  displaySize?: DisplaySize
}

function Badge(props: BadgeProps) {
  const {
    displaySize = DISPLAY_SIZE.md,
    className,
    ...spanProps
  } = props

  return (
    <span
      className={cn("badge", `badge_${displaySize}`, className)}
      {...spanProps}
    />
  )
}

export default Badge
