import "./frame.css"

import Frame from "./Frame"
import FrameContainer from "./FrameContainer"
import FrameHeader from "./FrameHeader"
import FrameBody from "./FrameBody"
import FrameFooter from "./FrameFooter"
import FrameBackdrop from "./FrameBackdrop"
import FrameCloseButton from "./FrameCloseButton"
import FrameFullscreenButton from "./FrameFullscreenButton"
import FrameMinimizeButton from "./FrameMinimizeButton"

export type { FrameProps } from "./Frame"
export type { FrameContainerProps } from "./FrameContainer"
export type { FrameHeaderProps } from "./FrameHeader"
export type { FrameBodyProps } from "./FrameBody"
export type { FrameFooterProps } from "./FrameFooter"
export type { FrameBackdropProps } from "./FrameBackdrop"
export type { FrameCloseButtonProps } from "./FrameCloseButton"
export type { FrameFullscreenButtonProps } from "./FrameFullscreenButton"
export type { FrameMinimizeButtonProps } from "./FrameMinimizeButton"

export default Object.assign(Frame, {
  Container: FrameContainer,
  Header: FrameHeader,
  Body: FrameBody,
  Footer: FrameFooter,
  Backdrop: FrameBackdrop,
  CloseButton: FrameCloseButton,
  FullscreenButton: FrameFullscreenButton,
  MinimizeButton: FrameMinimizeButton
})
