import type { MouseEvent } from "react"

function createRipple(e: MouseEvent<Element, globalThis.MouseEvent>) {
  const cmp = e.currentTarget

  const circle = document.createElement("span")
  const diameter = Math.max(cmp.clientWidth, cmp.clientHeight)
  const radius = diameter / 2
  circle.style.width = circle.style.height = `${diameter}px`

  const { top, left } = cmp.getBoundingClientRect()
  circle.style.left = `${e.clientX - left - radius}px`
  circle.style.top = `${e.clientY - top - radius}px`

  circle.classList.add("ripple")
  const ripple = cmp.getElementsByClassName("ripple")[0]
  if(ripple) ripple.remove()

  cmp.appendChild(circle)

  setTimeout(() => {
    circle.remove()
  }, 520)
}

export default createRipple
