import tinycolor, { type ColorInput } from "tinycolor2"

// import tinycolor, { ColorInput, Instance } from "tinycolor2"
// export default (fn: keyof Instance, color: ColorInput, ...args: any) => {
//   const c = tinycolor(color)[fn]
//   return c(args)
// }

export const getReadableColors = (
  color: ColorInput,
  threshold = 50
) => {
  if(!color) return {}
  const c = tinycolor(color)
  const fg = c.isDark()
    ? c.lighten(threshold)
    : c.darken(threshold)
  return {
    bg: color.toString(),
    fg: fg.toString()
  }
}

export const getTransparentColor = (
  color: ColorInput,
  amount = 0.2
): string => {
  if(!color) return ""
  return tinycolor(color)
    .setAlpha(amount)
    .toString()
}

export const lighten = (
  color: ColorInput,
  amount = 0.2
): string => {
  if(!color) return ""
  return tinycolor(color)
    .lighten(amount)
    .toString()
}

export const darken = (
  color: ColorInput,
  amount = 0.2
): string => {
  if(!color) return ""
  return tinycolor(color)
    .darken(amount)
    .toString()
}

export const brighten = (
  color: ColorInput,
  amount = 0.2
): string => {
  if(!color) return ""
  return tinycolor(color)
    .brighten(amount)
    .toString()
}

export const getColorByThreshold = (threshold: number) => {
  let color = ""
  if(threshold >= 80) color = "#27AE60" // "#00ea1c"
  else if(threshold >= 60) color = "#219653" // "#b4ea0a"
  else if(threshold >= 40) color = "#AE8B1E" // "yellow"
  else if(threshold >= 20) color = "#B46520" // "orange"
  else if(threshold >= 0) color = "#BC3838" // "red"
  return color
}

export function getRandomColorHueBySeed(seed: string) {
  const hash = [ ...seed ].reduce((acc, char) => {
    return char.codePointAt(0) + ((acc << 5) - acc)
  }, 0)
  return hash % 360
}

export function getRandomColorBySeed(seed: string) {
  if(!seed) return "#000000"
  const hue = getRandomColorHueBySeed(seed)
  return `hsl(${hue}, 95%, 35%)`
}
