import type { ReactNode, ComponentProps, CSSProperties, MouseEvent } from "react"
import { useState, useRef, useMemo, useEffect, useId } from "react"
import { usePopper } from "react-popper"
import type { PopperProps } from "react-popper"
import useResizeObserver from "@react-hook/resize-observer"
import type { Timeout } from "@/v1-common/types"
import { useOnClickOutside } from "@/v1-ui/utils/utils.clickOutside"
import cn from "@/v1-ui/utils/utils.cn"
import Portal from "@/v1-ui/Portal"

function isOutsideViewport(x: number, y: number) {
  const vw = window.innerWidth
    || document.documentElement.clientWidth
  const vh = window.innerHeight
    || document.documentElement.clientHeight

  if(x >= vw || y >= vh) return true
  if(x < 0 || y < 0) return true

  return false
}

const DELAYED_ACTIVATE_TIMEOUT = 100
const DELAYED_CLOSE_TIMEOUT = 75

type BaseProps = Omit<ComponentProps<"div">, "content">
export type PopoverProps<T = unknown> = BaseProps & {
  content: ReactNode,
  contentClassName?: ComponentProps<"div">["className"],
  contentStyle?: ComponentProps<"div">["style"],
  arrowClassName?: ComponentProps<"div">["className"],
  placement?: PopperProps<T>["placement"],
  modifiers?: PopperProps<T>["modifiers"],
  popoverClassName?: ComponentProps<"div">["className"],
  shown?: boolean,
  portalElement?: string,
  isPortal?: boolean,
  isAutoCloseDisabled?: boolean,
  isAutoCloseInsideEnabled?: boolean,
  isDelayable?: boolean,
  isShownIndicated?: boolean,
  autoCloseWhitelistIds?: string[],
  role?: ComponentProps<"div">["role"],
  onMouseEnter?(): void,
  onMouseLeave?(): void,
  onOpen?(): void,
  onClose?(): void,
  contentNoViewportOverflow?: boolean
  children?: ReactNode,
  onRef?(ref: HTMLSpanElement): void
}

function Popover<T>(props: PopoverProps<T>) {
  const {
    id: _id,
    className,
    style,
    content,
    contentClassName,
    contentStyle,
    arrowClassName,
    placement = "top",
    modifiers,
    popoverClassName,
    shown,
    portalElement = "#mount",
    isPortal = true,
    isAutoCloseDisabled = false,
    isAutoCloseInsideEnabled = false,
    isDelayable = false,
    isShownIndicated = true,
    autoCloseWhitelistIds,
    role,
    onOpen,
    onClose,
    onMouseEnter,
    onMouseLeave,
    children,
    contentNoViewportOverflow,
    onRef,
    ...divProps
  } = props

  const [ ucShown, setUcShown ] = useState(false)
  const [ popoverElem, setPopoverElem ] = useState<HTMLDivElement>(null)

  const activatorRef = useRef<HTMLSpanElement>(null)
  const arrowRef = useRef<HTMLDivElement>(null)

  const delayActivateTimeout = useRef<Timeout>()
  const delayCloseTimeout = useRef<Timeout>()
  const [ isDelayed, setisDelayed ] = useState(false)

  const isControlled = shown !== undefined
  const isShown = ucShown || shown === true

  const randomId = useId()
  const id = _id || randomId

  const isOnRef = useRef(false)
  useEffect(() => {
    if(isOnRef.current || !onRef) return
    onRef(activatorRef.current)
    isOnRef.current = true
  }, [
    onRef
  ])

  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
      if(delayActivateTimeout.current) {
        clearTimeout(delayActivateTimeout.current)
      }
      if(delayCloseTimeout.current) {
        clearTimeout(delayCloseTimeout.current)
      }
    }
  }, [])

  const { styles, attributes, update } = usePopper(activatorRef.current, popoverElem, {
    placement,
    modifiers: [{
      name: "arrow",
      options: {
        element: arrowRef.current,
        padding: 12
      }
    }, ...(modifiers || []) ] as PopoverProps<T>["modifiers"]
  })

  useResizeObserver(popoverElem, update)

  useEffect(() => {
    if(!update) return
    const elem = isPortal
      ? portalElement === "#mount"
        ? null
        : document.querySelector(portalElement)
      : popoverElem?.parentElement
    if(!elem) return
    const obs = new ResizeObserver(update)
    obs.observe(elem)
    return () => {
      obs.disconnect()
    }
  }, [
    update,
    isPortal,
    portalElement,
    popoverElem
  ])

  useOnClickOutside(useMemo(() => {
    if(!popoverElem || isAutoCloseDisabled) {
      return null
    }
    const whitelistIds = [
      activatorRef.current.id,
      ...(autoCloseWhitelistIds || [])
    ]
    if(!isAutoCloseInsideEnabled) {
      whitelistIds.push(popoverElem.id)
    }
    return {
      whitelistIds,
      onClick() {
        if(!isMounted.current) return
        if(!isControlled && ucShown) setUcShown(false)
        if(onClose) onClose()
      }
    }
  }, [
    autoCloseWhitelistIds,
    isAutoCloseDisabled,
    isAutoCloseInsideEnabled,
    popoverElem,
    isControlled,
    ucShown,
    onClose
  ]))

  // See more: https://github.com/facebook/react/issues/4492
  if(!isControlled
    && !isDelayed
    && ucShown
    && activatorRef.current
    && activatorRef.current.matches(":hover") === false
  ) {
    setUcShown(false)
  }

  function ucOnMouseEnter() {
    if(isControlled) return
    if(!ucShown) setUcShown(true)
    if(onOpen) onOpen()

    if(!isDelayable) return
    delayActivateTimeout.current = setTimeout(() => {
      setisDelayed(true)
    }, DELAYED_ACTIVATE_TIMEOUT)
  }

  function ucOnMouseLeave(e: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>) {
    if(isControlled) return
    if(isOutsideViewport(e.clientX, e.clientY)) return

    if(ucShown) {
      if(isDelayed) {
        delayCloseTimeout.current = setTimeout(() => {
          // Ensure popoverElem and activatorRef.current are not null before calling matches
          if(popoverElem && !popoverElem.matches(":hover")
            && activatorRef.current && !activatorRef.current.matches(":hover")) {
            setUcShown(false)
            setisDelayed(false)
          }
        }, DELAYED_CLOSE_TIMEOUT)
      } else {
        setUcShown(false)
      }
    }

    if(onClose) onClose()

    if(delayActivateTimeout.current) {
      clearTimeout(delayActivateTimeout.current)
      delayActivateTimeout.current = null
    }
  }

  function getPopoverContentMaxHeight() {
    if(!popoverElem) return 0
    const { height, bottom } = popoverElem.getBoundingClientRect()
    const viewportHeight = window.innerHeight
      || document.documentElement.clientHeight
    return viewportHeight - (bottom - (height - 20))
  }

  function getPopoverContentStyle() {
    if(!contentNoViewportOverflow) return contentStyle
    return {
      ...contentStyle,
      ...{
        maxHeight: getPopoverContentMaxHeight(),
        overflowY: "auto"
      } as CSSProperties
    }
  }

  const popover = (
    <div
      ref={setPopoverElem}
      className={cn("popover", popoverClassName)}
      style={styles.popper}
      id={id}
      aria-labelledby={`${id}-activator`}
      aria-hidden={!isShown}
      aria-label={divProps?.["aria-label"]}
      role={role}
      {...attributes.popper}
    >
      <div
        ref={arrowRef}
        className={cn("popover-arrow", arrowClassName, placement)}
        style={styles.arrow}
      />

      <div
        className={cn("popover-content", contentClassName)}
        style={getPopoverContentStyle()}
      >
        {content}
      </div>
    </div>
  )

  return (
    <span
      id={`${id}-activator`}
      style={style}
      className={cn(className, {
        "popover-content_is-shown": isShown && isShownIndicated
      })}
      aria-haspopup="true"
      aria-controls={isShown ? id : null}
      ref={activatorRef}
      onMouseEnter={onMouseEnter || ucOnMouseEnter}
      onMouseLeave={onMouseLeave || ucOnMouseLeave}
      {...divProps}
    >
      {children}

      {isShown
        ? isPortal
          ? <Portal element={portalElement}>
              {popover}
            </Portal>
          : popover
        : null
      }
    </span>
  )
}

export default Popover
