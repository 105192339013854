import { type ReactNode, useState, useMemo, useEffect } from "react"
import Portal, { type PortalProps } from "@/v1-ui/Portal"
import { type FrameContextProps, FrameContext }
  from "@/v1-ui/Frame/frame.context"

export type FrameProps = FrameContextProps & {
  /** default: false */
  isPortal?: boolean,
  /** default: #mount */
  portalElement?: PortalProps["element"],
  /** default: false */
  isAlwaysRendered?: boolean,
  children: ReactNode
}

function Frame(props: FrameProps) {
  const [ isFullscreen, setIsFullscreen ] = useState(props.isFullscreen)
  const [ isMinimize, setIsMinimize ] = useState(props.isMinimize)

  useEffect(() => {
    if(props.isShown) props.onShow?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.onShow,
    props.isShown
  ])

  const value = useMemo(() => {
    return {
      isShown: props.isShown,
      onShow: props.onShow,
      onHide: props.onHide,
      isClosedOnOutsideClick: props.isClosedOnOutsideClick ?? true,
      isClosedOnEscKey: props.isClosedOnEscKey ?? true,
      isFullscreen: props.isFullscreen ?? isFullscreen,
      onFullscreen(isFullscreen: boolean) {
        setIsFullscreen(isFullscreen)
        props.onFullscreen?.(isFullscreen)
      },
      isMinimize: props.isMinimize ?? isMinimize,
      onMinimize(isMinimize: boolean) {
        setIsMinimize(isMinimize)
        props.onMinimize?.(isMinimize)
      }
    }
  }, [
    isFullscreen,
    isMinimize,
    props
  ])

  const frame = (
    <FrameContext.Provider value={value}>
      {props.children}
    </FrameContext.Provider>
  )

  return props.isAlwaysRendered === true
    ? props.isPortal
      ? <Portal element={props.portalElement || "#mount"}>
          {frame}
        </Portal>
      : frame
    : props.isShown
      ? props.isPortal
        ? <Portal element={props.portalElement || "#mount"}>
            {frame}
          </Portal>
        : frame
      : null
}

export default Frame
