import type { SidebarItemProps } from "@/v1-ui/Sidebar"
import Sidebar from "@/v1-ui/Sidebar"
import Package from "@/v1-ui/Package"
import Badge from "@/v1-ui/Badge"
import cn from "@/v1-ui/utils/utils.cn"
import { PACKAGE_ACCESS_API_NAMES_MATCHING }
  from "@/v1-console/packageAccess/packageAccess.constants"
import PackageAccessGuard
  from "@/v1-console/packageAccess/PackageAccessGuard"
import { useProjectPanelOpener }
  from "@/v1-console/project/project.constants.utils"
import { trackAppSidebarNavigated }
  from "@/v1-console/app/app.tracking"

const API_NAME = PACKAGE_ACCESS_API_NAMES_MATCHING["Matching"]

export type baseProps = SidebarItemProps
export type AppSidebarItemsProjectMatchingProps = baseProps & {
  hasActiveProject: boolean
}

function AppSidebarItemsProjectMatching(props: AppSidebarItemsProjectMatchingProps) {
  const {
    hasActiveProject,
    className,
    isActive,
    ...SidebarItemProps
  } = props

  const opener = useProjectPanelOpener()

  return (
    <PackageAccessGuard
      isAlwaysRendered
      apiName={API_NAME}
    >
      {(guard) => (
        <Sidebar.Item
          isActive={opener.getIsActive("matching")}
          isDisabled={!hasActiveProject || !guard.HasAccess}
          className="app-sidebar_to-matching"
          contentClassName={cn("flex items-center", className, {
            "p-0 border-0 bg-transparent shadow-none": !!guard.NextPackage
          })}
          popoverIsAlwaysEnabled={!isActive && (!guard.HasAccess && !!guard.NextPackage)}
          popoverClassName={guard.NextPackage ? "pointer-events-auto" : undefined}
          popoverContent={guard.NextPackage
            ? <Package.Prompt
                packageCode={guard.NextPackageCode}
                packageIcon={guard.NextPackage.Icon}
                packageName={guard.NextPackage.Name}
              >
                <Package.PromptText
                  text="Matching er tilgjengelig i våre mer omfattende pakker."
                />
              </Package.Prompt>
            : null
          }
          iconSrc="search-heart"
          onClick={() => {
            opener.open("matching:nav")
            trackAppSidebarNavigated("Matching", {
              issueId: 6447,
              sprintNo: 26
            })
          }}
          {...SidebarItemProps}
        >
          Matching
          {!guard.HasAccess && guard.NextPackage
            ? <img
                className="ml-auto mr-1"
                src={guard.NextPackage.Icon}
                alt={guard.NextPackage.Name}
              />
            : <Badge className="bg-pink-500 text-bg0 py-1 px-0.5 ml-auto">
                Beta
              </Badge>
          }
        </Sidebar.Item>
      )}
    </PackageAccessGuard>
  )
}

export default AppSidebarItemsProjectMatching
