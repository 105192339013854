import cn from "@/v1-ui/utils/utils.cn"
import Frame, { type FrameFullscreenButtonProps } from "@/v1-ui/Frame"

export type Dialog2FullscreenButtonProps = FrameFullscreenButtonProps

function Dialog2FullscreenButton(props: Dialog2FullscreenButtonProps) {
  const { className, ...frameFullscreenButtonProps } = props
  return (
    <Frame.FullscreenButton
      className={cn("dialog2-fullscreen-button", className)}
      {...frameFullscreenButtonProps}
    />
  )
}

export default Dialog2FullscreenButton
