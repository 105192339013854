export const PACKAGE_CODES = {
  starter: 1,
  standard: 2,
  premium: 3
}
export type PackageNames = typeof PACKAGE_NAMES[keyof typeof PACKAGE_NAMES]

export const PACKAGE_NAMES = {
  [PACKAGE_CODES.starter]: "starter",
  [PACKAGE_CODES.standard]: "standard",
  [PACKAGE_CODES.premium]: "premium"
}
export type PackageCodes = typeof PACKAGE_CODES[keyof typeof PACKAGE_CODES]

export const DEFAULT_PROMPT_TEXT = "Utforsk fordelene ved å oppgradere:"
export const DEFAULT_PROMPT_URL = "https://placepoint.no/oppgrader"
