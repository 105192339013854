import type { Option } from "@/v1-console/analyze/analyze.types"
import { getAssetUrl } from "@/v1-ui/utils/utils.assets"
import { DEFAULT_POLYGON_SYMBOL }
  from "@/v1-map-ui/graphic/map.graphic.constants"
import config from "@/v1-console/config"

const SERVER_BASE_URL = config.layerServerBaseUrl_OLD

export const ANALYZE_OPTIONS: Option[] = [
  {
    Id: 1,
    ApiName: "Analyze_Floors_Buildings",
    Name: "Etasjer (bygg)",
    Description: "Viser etasjer på bygg, inkl. statistikk. For å enklere se ekstremverdier er fargeskalaen basert på den høyeste etasjen innenfor området.",
    Icon: getAssetUrl("/images/icons/analyze/stairs.png"),
    Type: "Dynamic",
    IsEnabled: true,
    Configuration: {
      QueryOptions: {
        url: `${SERVER_BASE_URL}/floors/FeatureServer/floors`
      },
      ExportMappings: [
        { label: "Bygningsnr", path: "attributes.bygningsnr" },
        { label: "Bygningstype", path: "attributes.bygningstype" },
        { label: "Næringsgruppe", path: "attributes.naringsgruppe" },
        { label: "Bygningstatus", path: "attributes.bygningstatus" },
        { label: "Etasjer", path: "attributes.count" },
        { label: "Etasje % av område", path: "attributes.percent" }
      ],
      TableMappings: [
        { Header: "Bygningsnr", id: "bygningsnr", priority: 1 },
        { Header: "Bygningstype", id: "bygningstype", priority: 2 },
        { Header: "Næringsgruppe", id: "naringsgruppe", priority: 3 },
        { Header: "Bygningstatus", id: "bygningstatus", priority: 4 },
        { Header: "Etasjer", id: "count", priority: 5 },
        { Header: "Etasje % av område", id: "percent", priority: 6 }
      ],
      IdField: "attributes.objectid",
      SortAttribute: "attributes.count",
      SortAsc: false,
      NavigateUrlTemplate: "/map/buildings/no/<attributes.bygningsnr>",
      Display: {
        Display1: "<attributes.bygningstype>",
        Display2: "<attributes.count> etasjer",
        Display3: null
      },
      LabelText: "<attributes.count> etg.",
      SymbolFromColor: "#288835",
      SymbolToColor: "#b5f1bd",
      SymbolColorKey: "attributes.percent",
      StatOptions: [{
        label: "Antall etasjer",
        attribute: "count",
        unit: "etg."
      }, {
        label: "Antall etasjer (%) av området",
        attribute: "percent",
        unit: "%"
      }]
    },
    issueId: 3503,
    sprintNo: 14
  },
  {
    Id: 2,
    ApiName: "Analyze_BRA_Buildings",
    Name: "BRA m² (bygg)",
    Description: "Viser det totale bruttoarealet (BRA) på byggene, inkl. statistikk. Fargeskalaen er basert på det høyeste bruttoarealet for å enklere se ekstremverdier.",
    Icon: getAssetUrl("/images/icons/analyze/sofa.png"),
    Type: "Dynamic",
    IsEnabled: true,
    Configuration: {
      QueryOptions: {
        url: `${SERVER_BASE_URL}/bra/FeatureServer/bra`
      },
      ExportMappings: [
        { label: "Bygningsnr", path: "attributes.bygningsnr" },
        { label: "Bygningstype", path: "attributes.bygningstype" },
        { label: "Næringsgruppe", path: "attributes.naringsgruppe" },
        { label: "Bygningstatus", path: "attributes.bygningstatus" },
        { label: "BRA m²", path: "attributes.bra" },
        { label: "BRA %", path: "attributes.percent" }
      ],
      TableMappings: [
        { Header: "Bygningsnr", id: "bygningsnr", priority: 1 },
        { Header: "Bygningstype", id: "bygningstype", priority: 2 },
        { Header: "Næringsgruppe", id: "naringsgruppe", priority: 3 },
        { Header: "Bygningstatus", id: "bygningstatus", priority: 4 },
        { Header: "BRA m²", id: "bra", priority: 5 },
        { Header: "BRA %", id: "percent", priority: 6 }
      ],
      IdField: "attributes.objectid",
      SortAttribute: "attributes.bra",
      SortAsc: false,
      NavigateUrlTemplate: "/map/buildings/no/<attributes.bygningsnr>",
      Display: {
        Display1: "<attributes.bygningstype>",
        Display2: "BRA: <attributes.bra> m²",
        Display3: null
      },
      LabelText: "<attributes.bra> m²",
      SymbolFromColor: "#cccccc",
      SymbolToColor: "#10305e",
      SymbolColorKey: "attributes.percent",
      StatOptions: [{
        label: "BRA (m²)",
        attribute: "bra",
        unit: "m²"
      }, {
        label: "BRA (%) per område",
        attribute: "percent",
        unit: "%"
      }]
    },
    issueId: 3503,
    sprintNo: 14
  },
  {
    Id: 3,
    ApiName: "Analyze_BRA_Cadastres",
    Name: "BRA% (tomt)",
    Description: `Bruksarealet for bebyggelsen på en tomt beregnes etter Norsk Standard NS 3940:2012, parkeringsarealet inngår også i beregningsgrunnlaget etter § 5-7.
    <br />
    <br />
    Summen av bruksarealet for bebyggelsen på en tomt er bruksareal for den enkelte bygning/ konstruksjon, det åpne overbygde areal og nødvendig parkeringsareal/biloppstillingsplasser.
    <br />
    <br />
    I bruksarealet skal det ikke fratrekkes areal som opptas av vegger, rør og ledninger, sjakter, søyler, innredningsenheter eller lignende innenfor bruksenheten.`,
    Icon: getAssetUrl("/images/icons/analyze/sofa2.png"),
    Type: "Dynamic",
    IsEnabled: true,
    Configuration: {
      QueryOptions: {
        url: `${SERVER_BASE_URL}/cadastre-bra/FeatureServer/cadastre-bra`
      },
      ExportMappings: [
        { label: "Matrikkelnr.", path: "attributes.cadastreNumber" },
        { label: "Adresse", path: "attributes.address" },
        { label: "Tomt m²", path: "attributes.plotSize" },
        { label: "BRA m²", path: "attributes.braSize" },
        { label: "BYA %", path: "attributes.braSizeOfPlotSizePercent" },
        { label: "BYA % av området", path: "attributes.braSizeOfPlotSizePercentOfResults" },
        { label: "Antall bygg", path: "attributes.buildingCount" }
      ],
      TableMappings: [
        { Header: "Matrikkelnr.", id: "cadastreNumber" },
        { Header: "Adresse", id: "address" },
        { Header: "Tomt m²", id: "plotSize" },
        { Header: "BRA m²", id: "braSize" },
        { Header: "BYA %", id: "braSizeOfPlotSizePercent" },
        { Header: "BYA % av området", id: "braSizeOfPlotSizePercentOfResults" },
        { Header: "Antall bygg", id: "buildingCount" }
      ],
      IdField: "attributes.objectid",
      SortAttribute: "attributes.braSizeOfPlotSizePercent",
      SortAsc: false,
      NavigateUrlTemplate: "/map/cadastres/replace(<attributes.cadastreNumber>, '-', '/')",
      Display: {
        Display1: "<attributes.cadastreNumber>",
        Display2: "<attributes.address>",
        Display3: "Tomt: <attributes.plotSize>m² / BRA: <attributes.braSize>m² = round(<attributes.braSizeOfPlotSizePercent>)%"
      },
      LabelText: "<attributes.braSize>m² (round(<attributes.braSizeOfPlotSizePercent>)%)",
      SymbolFromColor: "#cccccc8c",
      SymbolToColor: "#10305e8c",
      SymbolColorKey: "attributes.braSizeOfPlotSizePercent",
      SortOptions: [{
        label: "BRA-grad",
        attribute: "attributes.braSizeOfPlotSizePercent"
      }, {
        label: "BRA-grad av området",
        attribute: "attributes.braSizeOfPlotSizePercentOfResults"
      }, {
        label: "BRA",
        attribute: "attributes.braSize"
      }, {
        label: "Tomten",
        attribute: "attributes.plotSize"
      }],
      StatOptions: [{
        label: "BRA-grad (%)",
        attribute: "braSizeOfPlotSizePercent",
        unit: "%"
      }, {
        label: "BRA-grad (%) av området",
        attribute: "braSizeOfPlotSizePercentOfResults",
        unit: "%"
      }, {
        label: "BRA (m²)",
        attribute: "braSize",
        unit: "m²"
      }, {
        label: "Tomten (m²)",
        attribute: "plotSize",
        unit: "m²"
      }]
    },
    issueId: 3503,
    sprintNo: 14
  },
  {
    Id: 4,
    ApiName: "Analyze_BYA_Cadastres",
    Name: "BYA% (tomt)",
    Description: `Viser graden av bebyggelse på tomten. Regnet ut ved bruk av tomten sin grunnflate mot grunnflaten på byggene som er innenfor eller skjærer over tomtegrensen.
    <br />
    <br />
    Bebygd areal beregnes med utgangspunkt i Norsk Standard NS 3940:2012, men slik at parkeringsarealet inngår i beregningen etter TEK § 5-7.
    <br />
    <br />
    Bebygd areal for en tomt er summen av bebygd areal for alle bygninger, bygningsdeler og konstruksjoner over bakken, åpent overbygd areal og nødvendig areal for biloppstillingsplasser på tomta.
    <br />
    <br />
    Bebygd areal er "fotavtrykket" av bygninger, carport, overbygd gårdsrom, terrasse som ligger mer enn 0,5 m over terreng, utkraget bygningsdel med fri høyde over terreng mindre enn 5 m over terreng, parkeringsareal osv`,
    Icon: getAssetUrl("/images/icons/analyze/pie-chart.png"),
    Type: "Dynamic",
    IsEnabled: true,
    Configuration: {
      QueryOptions: {
        url: `${SERVER_BASE_URL}/utilization/FeatureServer/utilization`
      },
      ExportMappings: [
        { label: "Matrikkelnr", path: "attributes.cadastreNumber" },
        { label: "Adresse", path: "attributes.address" },
        { label: "Totalt", path: "attributes.total" },
        { label: "Brukt m²", path: "attributes.used" },
        { label: "Brukt %", path: "attributes.usedPercent" },
        { label: "Ledig m²", path: "attributes.available" },
        { label: "Ledig %", path: "attributes.availablePercent" }
      ],
      TableMappings: [
        { Header: "Matrikkelnr", id: "cadastreNumber", priority: 1 },
        { Header: "Adresse", id: "address", priority: 2 },
        { Header: "Totalt", id: "total", priority: 3 },
        { Header: "Brukt m²", id: "used", priority: 4 },
        { Header: "Brukt %", id: "usedPercent", priority: 5 },
        { Header: "Ledig m²", id: "available", priority: 6 },
        { Header: "Ledig %", id: "availablePercent", priority: 7 }
      ],
      IdField: "attributes.objectid",
      SortAttribute: "attributes.availablePercent",
      SortAsc: false,
      NavigateUrlTemplate: "/map/cadastres/replace(<attributes.cadastreNumber>, '-', '/')",
      Display: {
        Display1: "<attributes.cadastreNumber>",
        Display2: "<attributes.address>",
        Display3: "Ledig: round(<attributes.availablePercent>)%"
      },
      LabelText: "round(<attributes.used>)m² (round(<attributes.usedPercent>)%)",
      SymbolFromColor: "#cccccc8c",
      SymbolToColor: "#10305e8c",
      SymbolColorKey: "attributes.availablePercent",
      SortOptions: [{
        label: "Ledig",
        attribute: "attributes.available"
      }, {
        label: "Brukt",
        attribute: "attributes.used"
      }, {
        label: "Totalt",
        attribute: "attributes.total"
      }],
      StatOptions: [{
        label: "Ledig (%)",
        attribute: "availablePercent",
        unit: "%"
      }, {
        label: "Ledig (m²)",
        attribute: "available",
        unit: "m²"
      }, {
        label: "Brukt (%)",
        attribute: "usedPercent",
        unit: "%"
      }, {
        label: "Brukt (m²)",
        attribute: "used",
        unit: "m²"
      }, {
        label: "Tomten (m²)",
        attribute: "total",
        unit: "m²"
      }]
    },
    issueId: 3503,
    sprintNo: 14
  },
  {
    Id: 5,
    ApiName: "Analyze_Demographics_Area",
    Name: "Demografi (område)",
    Description: "Viser demografiske data for området. Dataene er hentet fra SSB og viser befolkningstetthet, aldersfordeling, utdanningsnivå, inntektsnivå, sysselsetting og boligtype.",
    Icon: getAssetUrl("/images/icons/analyze/people-together.png"),
    Type: "Custom",
    IsEnabled: true,
    issueId: 4608,
    sprintNo: 18
  }
  // @note: uncomment to enable when we get back geodata access to the layer or create our own
  // {
  //   Id: 6,
  //   ApiName: "Analyze_Risk_Buildings",
  //   Name: "Risiko (bygg)",
  //   Description: "Viser hvilke av noen utvalgte teamer av risiko (flom, steinsprang, skredd, brann, kvikkleire) som har blitt kartlagt på bygninger som faller innenfor de ulike risikosonene",
  //   Icon: getAssetUrl("/images/icons/analyze/caution.png"),
  //   Type: "Custom",
  //   IsEnabled: true,
  //   issueId: 3503,
  //   sprintNo: 14
  // }
]

export const RESULTS_LAYER_OPTIONS: __esri.GraphicsLayerProperties = {
  id: "analyze-results-layer",
  listMode: "hide"
}

export const LABELS_LAYER_OPTIONS: __esri.GraphicsLayerProperties = {
  id: "analyze-labels-layer",
  listMode: "hide"
}

export const ADD_FILL_COLOR = {
  r: 25,
  g: 251,
  b: 34,
  a: 0.1
} as __esri.Color

export const ADD_OUTLINE_COLOR = {
  ...ADD_FILL_COLOR,
  a: 1
} as __esri.Color

export const ADD_POLYGON_SYMBOL = {
  ...DEFAULT_POLYGON_SYMBOL,
  type: "simple-fill",
  color: ADD_FILL_COLOR,
  outline: {
    ...DEFAULT_POLYGON_SYMBOL.outline,
    color: ADD_OUTLINE_COLOR
  }
} as any

export const SKETCH_OPTIONS: __esri.SketchViewModelProperties = {
  updateOnGraphicClick: true,
  // tooltipOptions: {
  //   enabled: true
  // },
  defaultCreateOptions: {
    hasZ: false
  },
  defaultUpdateOptions: {
    enableZ: false,
    toggleToolOnClick: false,
    tool: "reshape"
  },
  polygonSymbol: ADD_POLYGON_SYMBOL
}

export const ANALYZE_PARAM_KEY = "analyzeOptionId"
export const ANALYZE_DRAW_LIMIT = 2_500_000
