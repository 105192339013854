import type { ReactNode } from "react"
import { MsalProvider } from "@azure/msal-react"
import { instance } from "./auth.instance"
import AuthNavigation from "./AuthNavigation"
import AuthLoader from "./AuthLoader"

type AuthProviderProps = {
  children: ReactNode
}

function AuthProvider(props: AuthProviderProps) {
  const { children } = props
  return (
    <AuthNavigation instance={instance}>
      <MsalProvider instance={instance}>
        <AuthLoader>
          {children}
        </AuthLoader>
      </MsalProvider>
    </AuthNavigation>
  )
}

export default AuthProvider
