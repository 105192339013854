import { useEffect, useRef } from "react"
import { getCurrentHub } from "@sentry/react"
import { useLocation, type Location } from "react-router-dom"
import { parseParams, parsePath } from "@/v1-console/utils/http.utils"
import { type TrackProperties, page } from "@/v1-console/analytics"
import type { Obj } from "@/v1-common/types"
import config from "@/v1-console/config"
import { type RouteInfo, ROUTES }
  from "@/v1-console/router/router.constants"

const ROUTES_VALUES = Object.values(ROUTES)

export function parseLocationVars(loc: Location, tmp?: string) {
  return {
    ...parsePath(loc.pathname, tmp),
    ...parseParams(loc.hash),
    ...parseParams(loc.search),
    ...loc.state as Obj
  }
}

export function getRouteInfo(location: Location) {
  const routes: RouteInfo[] = []

  for(const route of ROUTES_VALUES) {
    let inc = false
    if(route.template) {
      const regex = route.template.replaceAll(/:[^\s/]+/g, "([\\w-_]+)")
      const matcher = new RegExp(regex)
      inc = matcher.test(location.pathname)
    }
    if(route.search) {
      inc = location.search.includes(route.search)
    }
    if(inc) {
      route.vars = parseLocationVars(location, route.template)
      routes.push(route)
    }
  }

  if(routes) return routes

  return [{
    category: "Other",
    template: location.pathname,
    name: location.pathname,
    vars: parseLocationVars(location),
    issueId: 0,
    sprintNo: 0
  }]
}

function getActiveTransaction() {
  const hub = getCurrentHub()
  const scope = hub.getScope()
  return scope?.getTransaction()
}

export function useRouteInstrumentation(location: Location) {
  const prev = useRef<Location>(null)

  useEffect(() => {
    if(!config.isInstrumentationEnabled) return
    if(!location.pathname) return

    let prevRoute: RouteInfo
    if(prev.current) {
      const routes = getRouteInfo(prev.current)
      prevRoute = routes[0]
    }

    const routes = getRouteInfo(location)
    const route = routes?.[0]

    if(!route) return

    const props: Obj = {
      title: route.name,
      path: route.template,
      vars: route.vars,
      issueId: route.issueId,
      sprintNo: route.sprintNo
    }

    if(route.latestIssueId) props.latestIssueId = route.latestIssueId
    if(route.latestSprintNo) props.latestSprintNo = route.latestSprintNo

    const existingTransaction = getActiveTransaction()
    const transaction = existingTransaction || getCurrentHub()
      .startTransaction({
        op: "navigation",
        name: route.template
      })

    if(transaction.op === "pageload") {
      transaction.setName(route.template)
    }

    const span = transaction.startChild({
      op: "navigation",
      description: route.template
    })

    span.setTag("op", span.op)
    span.setTag("navigation.to", route.template)
    span.setData("toPathName", route.name)

    if(prevRoute) {
      span.setTag("navigation.from", prevRoute.name)
      span.setData("prevRoute.template", prevRoute.template)
      props.fromTitle = prevRoute.name
      props.fromPath = prevRoute.template
    }

    page(route.category, route.name, props as TrackProperties)
    span.finish()
    prev.current = location
  }, [
    location
  ])
}

function RouterInstrumenter() {
  const location = useLocation()
  useRouteInstrumentation(location)
  return null
}

export default RouterInstrumenter
