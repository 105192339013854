import { type TrackProperties, track } from "@/v1-console/analytics"

type ExternalLinkInput = TrackProperties & {
  to: string,
  title: string
}

export function trackExternalLink(input: ExternalLinkInput) {
  track("Common_ExternalLink_Navigated", {
    module: "Common",
    source: "Link",
    action: "Navigated",
    ...input
  })
}
