import { useState, useEffect, useRef } from "react"
import { useIsAuthenticated } from "@azure/msal-react"
import ActivityIndicator from "@/v1-ui/ActivityIndicator"
import AuthLoginPage from "./AuthLoginPage"
import { acquireAndCheckTokenOrRedirectToLogin }
  from "@/v1-console/auth/auth.instance"

export type AuthGuardProps = {
  children: any
}

function AuthGuard(props: AuthGuardProps) {
  const isAuthenticated = useIsAuthenticated()
  const [ isFetchingToken, setIsFetchingToken ] = useState(true)
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if(!isAuthenticated) return
    acquireAndCheckTokenOrRedirectToLogin()
      .then(() => {
        if(isMounted.current) {
          setIsFetchingToken(false)
        }
      })
  }, [
    isAuthenticated
  ])

  if(!isAuthenticated) {
    return <AuthLoginPage />
  }

  if(isFetchingToken) {
    return (
      <ActivityIndicator theme="dark">
        Venter på autentisering (token)..
      </ActivityIndicator>
    )
  }

  return props.children
}

export default AuthGuard
