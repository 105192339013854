const i18n: Record<string, Record<string, string>> = {
  en: {
    "VarChar": "Text",
    "Date": "Date",
    "Bit": "Truth",
    "Int": "Number",
    "Float": "Decimals",
    "GnrBnr": "Kmr-Gnr-Bnr-Fnr-Snr",
    "Url": "Link",
    "Orgnr": "Org. nr.",
    "E-mail": "E-mail",
    "Phone": "Phone",
    "age": "Age",
    "year": "year",
    "building": "Building",
    "housing": "Housing",
    "population": "Population",
    "ownership": "Ownership",
    "wealth": "Wealth",
    "income": "Income",
    "family": "Family",
    "maritalStatus": "Marital Status",
    "educationSubject": "Education Subject",
    "educationLevel": "Education Level",
    "point": "Point",
    "polygon": "Polygon",
    "rectangle": "Rectangle",
    "circle": "Circle",
    "blue": "Blue",
    "purple": "Purple",
    "red": "Red",
    "orange": "Orange",
    "yellow": "Yellow",
    "green": "Green",
    "gray": "Gray",
    "location.Addresses": "Addresses",
    "property.Lots": "Lots",
    "property.Buildings": "Buildings",
    "industry.Companies": "Companies",
    "location.Streets": "Streets",
    "traffic.ParkingAreas": "ParkingAreas",
    "location.Counties": "Counties",
    "location.Municipalities": "Municipalities",
    "location.BasicStatisticalUnits": "BasicStatisticalUnits",
    "location.SubAreas": "SubAreas",
    "location.CityDistricts": "CityDistricts",
    "traffic.AddressesVolumes": "AddressesVolumes",
    "traffic.TransitStops": "TransitStops",
    "Private": "Private",
    "Within tenant": "With colleagues",
    "Global": "Global",
    "true": "Yes",
    "false": "No",
    "Shares": "Shares",
    "Organizational subunit": "Organizational subunit",
    "planId": "Plan id",
    "planName": "Plan name",
    "planTypeName": "Plan type",
    "planStatus": "Plan status",
    "planProvision": "Plan provision",
    "suggesterType": "Suggester type",
    "legalRefTypeName": "Legal reference type",
    "legalRefDescription": "Legal reference desc.",
    "verticalName": "Vertical name",
    "municipalityCode": "Municipality code",
    "declaredAt": "Declared at",
    "inEffectAt": "Took effect at",
    "inEffectYear": "Took effect year",
    "finalizedAt": "Finalized at",
    "copiedAt": "Copied at",
    "updatedAt": "Updated at",
    "originalPlanId": "Original plan id",
    "originalHost": "Original data host",
    "extraInfo": "Extra info",
    "objType": "Type",
    "purpose": "Purpose",

    "name": "Name",
    "organizationNumber": "Organization number",
    "numberOfEmployees": "Number of employees",
    "legalEntityType": "Legal entity type",
    "industrialSector": "Industrial sector",
    "industrialClassification": "Industrial classification",
    "address": "Address",
    "Task": "Task",
    "Note": "Note",

    "solid": "Solid",
    "backward-diagonal": "Backward diagonal",
    "forward-diagonal": "Forward diagonal",
    "cross": "Cross",
    "diagonal-cross": "Diagonal cross",
    "horizontal": "Horizontal",
    "vertical": "Vertical",
    "none": "None"
  },

  no: {
    "VarChar": "Tekst",
    "Date": "Dato",
    "Bit": "Sannhet",
    "Int": "Tall",
    "Float": "Desimaler",
    "GnrBnr": "Kmr-Gnr-Bnr-Fnr-Snr",
    "Url": "Link",
    "Orgnr": "Org. nr.",
    "E-mail": "E-post",
    "Phone": "Telefon",
    "age": "Alder",
    "year": "år",
    "building": "Bygg",
    "housing": "Boforhold",
    "population": "Populasjon",
    "ownership": "Eieform",
    "wealth": "Formue",
    "income": "Inntekt",
    "family": "Familje",
    "maritalStatus": "Sivilstatus",
    "educationSubject": "Utdanningsfelt",
    "educationLevel": "Utdanningsnivå",
    "point": "Punkt",
    "polygon": "Polygon",
    "rectangle": "Rektangel",
    "circle": "Sirkel",
    "blue": "Blå",
    "purple": "Lilla",
    "red": "Rød",
    "orange": "Oransj",
    "yellow": "Gul",
    "green": "Grønn",
    "gray": "Grå",
    "location.Addresses": "Adresser",
    "property.Lots": "Eiendommer",
    "property.Buildings": "Bygninger",
    "industry.Companies": "Selskap",
    "location.Streets": "Gater",
    "traffic.ParkingAreas": "Parkeringsområder",
    "location.Counties": "Fylker",
    "location.Municipalities": "Kommuner",
    "location.BasicStatisticalUnits": "Grunnkretser",
    "location.SubAreas": "Inndelte områder",
    "location.CityDistricts": "Bydeler",
    "traffic.AddressesVolumes": "Adresse volum",
    "traffic.TransitStops": "Holdeplasser",
    "Private": "Privat",
    "Within tenant": "Med kollegaer",
    "Global": "Globalt",
    "true": "Ja",
    "false": "Nei",
    "Shares": "Aksjer",
    "Organizational subunit": "Organisatorisk underenhet",
    "planId": "Plan id",
    "planName": "Plan navn",
    "planTypeName": "Plan type",
    "planStatus": "Plan status",
    "planProvision": "Plan bestemmelse",
    "suggesterType": "Forslagsstiller type",
    "legalRefTypeName": "Lovreferanse type",
    "legalRefDescription": "Lovreferanse beskrivelse",
    "verticalName": "Vertikalnivå",
    "municipalityCode": "Kommune nr.",
    "declaredAt": "Kunngjøringsdato",
    "inEffectAt": "Ikrafttredelse dato",
    "inEffectYear": "Ikrafttredelse år",
    "finalizedAt": "Vedtakendelig plan dato",
    "copiedAt": "Kopiert den",
    "updatedAt": "Oppdatert den",
    "originalPlanId": "Original plan id",
    "originalHost": "Original data vert",
    "extraInfo": "Ekstra info",
    "objType": "Type",
    "purpose": "Formål",

    "name": "Navn",
    "organizationNumber": "Organisasjonsnummer",
    "numberOfEmployees": "Antall ansatte",
    "legalEntityType": "Juridisk enhetstype",
    "industrialSector": "Industrisektor",
    "industrialClassification": "Industriell klassifisering",
    "address": "Adresse",
    "Task": "Oppgave",
    "Note": "Notat",
    "Property - Public journals": "Saksinnsyn",

    "solid": "Heldekkende",
    "backward-diagonal": "Diagonal stigende",
    "forward-diagonal": "Diagonal synkende",
    "cross": "Ruter",
    "diagonal-cross": "Diagonal ruter",
    "horizontal": "Vannrett",
    "vertical": "Loddrett",
    "none": "Ingen"
  }
}

export const $t = (key: string | boolean, locale = "no") => {
  const k = `${key}`
  return i18n[locale][k] || k
}
