import type { FieldPolicy } from "@apollo/client"

export const getCompanySummary: FieldPolicy = {
  read(_, { args, toReference }) {
    return toReference({
      __typename: "CompanySummary",
      Id: args.input.companyId
    })
  }
}
