import dayjs from "dayjs"
import "dayjs/locale/nb"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.locale("nb")
dayjs.extend(relativeTime)
// eslint-disable-next-line unicorn/prefer-export-from
export const date = dayjs

export function assertDate(date): date is Date{
  return date instanceof Date && !Number.isNaN(date.valueOf())
}

export function formatDate(
  date?: string | number | Date | dayjs.Dayjs,
  format = "HH:MM - DD MMM, YYYY"
) {
  return dayjs(date).format(format)
}

export type Seconds = number
export type TimeUnit = "h" | "m" | "s"
export type Time = {
  h: number,
  m: number,
  s: number
}

export function getTimeFromSeconds(seconds: Seconds) {
  return {
    h: Math.floor((seconds / 3600)),
    m: Math.floor(((seconds % 3600) / 60)),
    s: Math.floor(seconds % 60)
  }
}

function assertTimeUnit(v?: number) {
  return v !== undefined && v !== 0
}

export function getSecondsFromTime(time: Partial<Time>) {
  let total = 0
  if(assertTimeUnit(time.h)) total += (time.h * 60 * 60)
  if(assertTimeUnit(time.m)) total += (time.m * 60)
  if(assertTimeUnit(time.s)) total += time.s
  return total
}
