import type { FallbackRender } from "@sentry/react"
import ErrorGeneric from "./ErrorGeneric"
import ErrorOutdated from "./ErrorOutdated"

const ErrorFallback: FallbackRender = (props) => {
  const { error } = props

  if(error.name === "ChunkLoadError") {
    return <ErrorOutdated />
  }

  return <ErrorGeneric {...props} />
}

export default ErrorFallback
