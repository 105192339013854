import type { ReactNode } from "react"

export type ConditionalWrapperProps = {
  renderWrapper?(children: ReactNode): JSX.Element,
  condition?: boolean,
  children?: JSX.Element
}

function ConditionalWrapper(props: ConditionalWrapperProps) {
  const {
    condition,
    renderWrapper,
    children
  } = props

  return condition && renderWrapper
    ? renderWrapper(children)
    : children
}

export default ConditionalWrapper
