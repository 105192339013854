import type { RedirectRequest } from "@azure/msal-browser"
import { useMatch } from "react-router-dom"
import cn from "@/v1-ui/utils/utils.cn"
import Icon from "@/v1-ui/Icon"
import Button, { type ButtonProps } from "@/v1-ui/Button"
import { useSearchParams } from "@/v1-console/router/router.utils"
import { login } from "./auth.instance"

export type AuthLoginButtonProps = ButtonProps

function AuthLoginButton(props: AuthLoginButtonProps) {
  const { className, ...buttonProps } = props
  const isLoginRoute = useMatch("/login")
  const { 0: searchParams } = useSearchParams()

  function onClick() {
    const opts: Partial<RedirectRequest> = {}
    const r = searchParams.get("r")
    if(r) {
      opts.redirectStartPage = r
    } else if(isLoginRoute) {
      opts.redirectStartPage = "/map"
    }
    login(opts)
  }

  return (
    <Button
      intent="primary"
      className={cn("auth-login__to-login py-4 pl-6 pr-4 font-medium", className)}
      onClick={onClick}
      {...buttonProps}
    >
      Gå til login

      <Icon
        src="angle-right"
        className="mr-0 ml-2.5"
        size={12}
      />
    </Button>
  )
}

export default AuthLoginButton
