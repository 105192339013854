import { withProfiler, ErrorBoundary } from "@sentry/react"
import { NotificationProvider } from "@/v1-ui/Notification"
import ErrorFallback from "@/v1-console/error/ErrorFallback"
import Router from "@/v1-console/router/Router"

function App() {
  return (
    <ErrorBoundary
      showDialog
      fallback={ErrorFallback}
    >
      <NotificationProvider>
        <Router />
      </NotificationProvider>
    </ErrorBoundary>
  )
}

export default withProfiler(App)
