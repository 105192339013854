import type { ReactNode, ComponentProps } from "react"
import cn from "@/v1-ui/utils/utils.cn"
import Package from "@/v1-ui/Package"
import type { PackageCodes } from "./package.constants"

export type PackagePromptProps = ComponentProps<"div"> & {
  packageCode?: PackageCodes,
  packageName?: string,
  packageIcon?: string,
  badgeClassName?: string,
  isCustom?: boolean,
  children?: ReactNode,
}

function PackagePrompt(props: PackagePromptProps) {
  const {
    packageCode,
    packageName,
    packageIcon,
    badgeClassName,
    isCustom,
    children,
    className,
    ...divProps
  } = props

  return (
    <div
      className={cn("package-prompt", className)}
      {...divProps}
    >
      <Package.Badge
        className={cn("package-prompt-badge", badgeClassName)}
        packageCode={packageCode}
        packageName={packageName}
        packageIcon={packageIcon}
        isCustom={isCustom}
      />

      {children
        ? children
        : <Package.PromptText />
      }
    </div>
  )
}

export default PackagePrompt
