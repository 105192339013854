import type { ComponentProps, ReactNode, MouseEvent } from "react"
import { useEffect } from "react"
import { motion } from "framer-motion"
import type { Intent } from "@/v1-ui/types"
import cn from "@/v1-ui/utils/utils.cn"
import useTimeout from "@/v1-ui/utils/utils.timeout"
import Icon from "@/v1-ui/Icon"
import type { IconProps } from "@/v1-ui/Icon"
import Button from "@/v1-ui/Button"
import type { ButtonProps } from "@/v1-ui/Button"
import ConditionalWrapper from "@/v1-ui/ConditionalWrapper"

const initial = {
  opacity: 0,
  y: 50,
  scale: 0.3
}

const animate = {
  opacity: 1,
  y: 0,
  scale: 1
}

const exit = {
  opacity: 0,
  scale: 0.5,
  transition: {
    duration: 0.2
  }
}

type Placement = (
  "top-right" |
  "top-left" |
  "top-center" |
  "bottom-right" |
  "bottom-left" |
  "bottom-center" |
  "center-center"
)

export type NotificationProps = {
  title?: ReactNode,
  intent?: Intent,
  className?: ComponentProps<"div">["className"],
  style?: ComponentProps<"div">["style"],
  timeout?: number | false,
  minTimeout?: number | false,
  loading?: boolean,
  placement?: Placement,
  isBackdropEnabled?: boolean,
  iconSrc?: IconProps["src"],
  iconClassName?: IconProps["className"],
  iconStyle?: IconProps["style"],
  iconSize?: IconProps["size"],
  wrapperClassName?: ComponentProps<"div">["className"],
  wrapperStyle?: ComponentProps<"div">["style"],
  titleClassName?: ComponentProps<"div">["className"],
  titleStyle?: ComponentProps<"div">["style"],
  contentClassName?: ComponentProps<"div">["className"],
  contentStyle?: ComponentProps<"div">["style"],
  closeButtonIsShown?: boolean,
  closeButtonClassName?: ButtonProps["className"],
  closeButtonIconSrc?: ButtonProps["iconSrc"],
  closeButtonIconSize?: ButtonProps["iconSize"],
  closeButtonTiny?: ButtonProps["small"],
  closeButtonStyle?: ButtonProps["style"],
  closeButtonOnClick?: ButtonProps["onClick"],
  onDismiss: (e?: KeyboardEvent | MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void,
  isDismissedOnEscKey?: boolean,
  role?: ComponentProps<"div">["role"],
  "aria-label"?: ComponentProps<"div">["aria-label"],
  children?: ReactNode
}

function Notification(props: NotificationProps) {
  const {
    intent,
    className,
    timeout = 3000,
    loading,
    placement,
    isBackdropEnabled = false,
    iconSrc,
    iconClassName,
    iconStyle,
    iconSize = 20,
    wrapperClassName,
    wrapperStyle,
    title,
    titleClassName,
    titleStyle,
    contentClassName,
    contentStyle,
    closeButtonIsShown = true,
    closeButtonClassName,
    closeButtonIconSrc = "cross" as IconProps["src"],
    closeButtonIconSize = 10,
    closeButtonTiny = true,
    closeButtonStyle,
    closeButtonOnClick,
    onDismiss,
    isDismissedOnEscKey = true,
    children,
    role = "alertdialog",
    ...restProps
  } = props

  const cancel = useTimeout(() => {
    if(timeout) onDismiss()
  }, timeout || 0)

  useEffect(() => {
    if(!onDismiss || !isDismissedOnEscKey) return
    const keydown = (e: KeyboardEvent) => {
      if(e.key === "Escape") onDismiss(e)
    }
    document.addEventListener("keydown", keydown, false)
    return () => {
      document.removeEventListener("keydown", keydown, false)
    }
  }, [
    onDismiss,
    isDismissedOnEscKey
  ])

  return (
    <ConditionalWrapper
      condition={isBackdropEnabled}
      renderWrapper={(children) => (
        <div className="notification-item-backdrop">
          {children}
        </div>
      )}
    >
      <ConditionalWrapper
        condition={!!placement}
        renderWrapper={(children) => (
          <div className={cn("notification-placement", placement)}>
            {children}
          </div>
        )}
      >
        <motion.div
          className={cn("notification-item", intent, className, placement, {
            "no-title": !title,
            "has-close-btn": closeButtonIsShown
          })}
          initial={initial}
          animate={animate}
          exit={exit}
          role={role}
          onMouseEnter={() => {
            cancel()
          }}
          {...restProps}
        >
          {(iconSrc || loading)
            ? <Icon
                className={cn("notification-item-icn", iconClassName)}
                style={iconStyle}
                src={iconSrc}
                loading={loading}
                size={iconSize}
              />
            : null
          }

          <div
            className={cn("notification-item-wrapper", wrapperClassName)}
            style={wrapperStyle}
          >
            {title
              ? <div
                  className={cn("notification-item-title", titleClassName)}
                  style={titleStyle}
                >
                  {title}
                </div>
              : null
            }

            {children
              ? <div
                  className={cn("notification-item-content", contentClassName)}
                  style={contentStyle}
                >
                  {children}
                </div>
              : null
            }
          </div>

          {closeButtonIsShown
            ? <Button
                className={cn("notification-close-btn", closeButtonClassName)}
                style={closeButtonStyle}
                iconSrc={closeButtonIconSrc}
                iconSize={closeButtonIconSize}
                tiny={closeButtonTiny}
                onClick={(e) => {
                  if(closeButtonOnClick) closeButtonOnClick(e)
                  onDismiss(e)
                }}
              />
            : null
          }
        </motion.div>
      </ConditionalWrapper>
    </ConditionalWrapper>
  )
}

export default Notification
