export const DISPLAY_SIZE = {
  xl: "xl",
  lg: "lg",
  md: "md",
  sm: "sm",
  xs: "xs"
} as const

export type DisplaySize = keyof typeof DISPLAY_SIZE

// use includes for grouping by an array that includes
// something with an id inside
export const GROUP_TYPE = {
  match_unique: "match_unique",
  includes: "includes"
} as const

export type GroupType = keyof typeof GROUP_TYPE

export const ASSETS_BASE_URL = "https://assets.placepoint.no"
