import { createContext, useContext } from "react"

type SidebarContextProps = {
  isMini?: boolean
}

export const SidebarContext = createContext<SidebarContextProps>({
  isMini: false
})

function useSidebarContext() {
  const context = useContext(SidebarContext)
  if(!context) {
    throw new Error(
      "Sidebar compound components cannot be rendered outside the Sidebar component"
    )
  }
  return context
}

export default useSidebarContext
