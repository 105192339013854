/// <reference types="@types/segment-analytics" />

type Analytics = typeof window.analytics & {
  invoked: boolean,
  methods: string[],
  _writeKey: string,
  _loadOptions: any,
  SNIPPET_VERSION: string,
  initialize: any,
  factory: any,
  push: any
}

const METHODS = [
  "trackSubmit",
  "trackClick",
  "trackLink",
  "trackForm",
  "pageview",
  "identify",
  "reset",
  "group",
  "track",
  "ready",
  "alias",
  "debug",
  "page",
  "once",
  "off",
  "on",
  "addSourceMiddleware",
  "addIntegrationMiddleware",
  "setAnonymousId",
  "addDestinationMiddleware"
]

const SCRIPT_BASE_URL = "https://cdn.segment.com/analytics.js/v1"

function load(key: string, options?: Record<string, unknown>) {
  const a = (window.analytics || []) as Analytics
  if(a.initialize || a.invoked) {
    if(a.invoked) {
      // eslint-disable-next-line no-console
      console.error("Segment snippet included twice")
    }
    return
  }

  a._loadOptions = options
  a.invoked = true
  a.methods = METHODS
  a.factory = (e) => {
    return (...args) => {
      const t = Array.prototype.slice.call(args)
      t.unshift(e)
      a.push(t)
      return a
    }
  }

  for(let e = 0; e < a.methods.length; e++) {
    const key = a.methods[e]
    a[key] = a.factory(key)
  }

  const t = document.createElement("script")
  t.type = "text/javascript"
  t.async = true
  t.src = `${SCRIPT_BASE_URL}/${key}/analytics.min.js`

  const n = document.querySelectorAll("script")[0]
  n.parentNode.insertBefore(t, n)

  a._writeKey = key
  a.SNIPPET_VERSION = "4.15.3"

  window.analytics = a
}

export default load
