import cn from "@/v1-ui/utils/utils.cn"
import Frame, { type FrameBackdropProps } from "@/v1-ui/Frame"

export type Dialog2BackdropProps = FrameBackdropProps

function Dialog2Backdrop(props: Dialog2BackdropProps) {
  const { className, ...frameBackdropProps } = props
  return (
    <Frame.Backdrop
      className={cn("dialog2-backdrop", className)}
      {...frameBackdropProps}
    />
  )
}

export default Dialog2Backdrop
