import { Suspense } from "react"
import {
  unstable_HistoryRouter as HistoryRouter
} from "react-router-dom"
import ActivityIndicator from "@/v1-ui/ActivityIndicator"
import AuthProvider from "@/v1-console/auth/AuthProvider"
import Routes from "./Routes"
import RouterInstrumenter from "./RouterInstrumenter"
import history from "./router.history"

function Router() {
  return (
    <HistoryRouter history={history as any}>
      <AuthProvider>
        <Suspense fallback={(
          <ActivityIndicator theme="dark">
            Laster inn grensesnittet..
          </ActivityIndicator>
        )}>
          <Routes />
        </Suspense>

        <RouterInstrumenter />
      </AuthProvider>
    </HistoryRouter>
  )
}

export default Router
