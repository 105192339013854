import { useRef, useEffect, useCallback } from "react"

// if you create a new callback each render, then previous
// callback will be cancelled on render
function useTimeout(
  callback: () => void,
  timeout = 0
) {
  const timeoutIdRef = useRef<any>()
  const cancel = useCallback(() => {
    const timeoutId = timeoutIdRef.current
    if(timeoutId) {
      timeoutIdRef.current = undefined
      clearTimeout(timeoutId)
    }
  }, [
    timeoutIdRef
  ])

  useEffect(() => {
    timeoutIdRef.current = setTimeout(callback, timeout)
    return cancel
  }, [
    timeout,
    callback,
    cancel
  ])

  return cancel
}

export default useTimeout
