import type { ComponentProps } from "react"
import cn from "@/v1-ui/utils/utils.cn"

export type ErrorMessageProps = ComponentProps<"div"> & {
  error: Error
}

function ErrorMessage(props: ErrorMessageProps) {
  const {
    error,
    className,
    ...divProps
  } = props

  if(!error?.message) return null

  return (
    <div
      className={cn("error-message text-red-600 px-3", className)}
      role="alert"
      aria-label="Feil oppstod"
      {...divProps}
    >
      {error.message}
    </div>
  )
}

export default ErrorMessage
