import commonConfig from "@/v1-common/config"

const config = {
  ...commonConfig,

  userpilotKey: "NX-e5a5c9af",
  segmentKey: "VpbNnSr6KWV6str9jIGWETpPJqOdkBgd",
  gcpKey: "AIzaSyAg46PDPvHviYSV0vNOWLKjT3oQbTOp8WE",
  sentryDsn: "https://f5ef42389f8b4e15bdfb2eecb239aaf8@o40672.ingest.sentry.io/5454012",

  apiUrl: "",
  graphqlUrl: "/graphql",
  socketUrl: "ws://localhost:8080/graphql",

  matchingBaseUrl: "https://v1-matching-api-next.azurewebsites.net",
  fileUploaderUrl: "https://v1-file-uploader-next.azurewebsites.net",
  customerApiBaseUrl: "https://v1-customer-api-next.azurewebsites.net",
  pcEmbedIframeUrl: "https://stagingapp.propcloud.no/#",

  azAuthTenantId: "57fe2dc0-207e-4aa0-b2e6-079db06dc4e9",
  azAuthClientId: "ac6b8323-c6c9-4bcb-97c7-a94dc6ad2ba9",
  azAuthPostLogoutRedirectUri: "/",
  azAuthRedirectUrl: "/",

  geodataServerUrl: "https://services.geodataonline.no/arcgis",
  geodataServerBaseUrl: "https://services.geodataonline.no/arcgis/rest/services",
  layerServerBaseUrl: "https://v1-layer-server2-next.azurewebsites.net/arcgis/rest/services",
  layerServerBaseUrl_OLD: "https://v1-layer-server-next.azurewebsites.net",

  companyAccountingBaseUrl: "https://www.proff.no/bransjesøk",
  companyCreditCheckBaseUrl: "https://www.forvalt.no",
  companyHierarchyBaseUrl: "https://www.proff.no/roles",

  thirdPartyCredits: "https://placepoint.no/docs/credits",
  docsUrl: "https://docs.placepoint.no"
}

if(config.isRemote) {
  config.apiUrl = "https://v1-console-api-next.azurewebsites.net"
  config.graphqlUrl = "https://v1-console-api-next.azurewebsites.net/graphql"
  // config.socketUrl = "wss://v1-console-api-next.azurewebsites.net/graphql"
}

if(config.isProd) {
  const { host } = window.location
  const apiHost = host.replace("console", "api")
  config.apiUrl = `https://${apiHost}`
  config.graphqlUrl = `https://${apiHost}/graphql`
  // config.socketUrl = `wss://${apiHost}/graphql`
  config.socketUrl = "wss://app-geoapp-api-prod-001.azurewebsites.net/graphql"

  config.azAuthTenantId = "41139673-f9a9-4b9c-aceb-289c66832c22"
  config.azAuthClientId = "76e21198-3e4a-4e0a-a691-baa4a98bf32f"
  config.fileUploaderUrl = "https://file-uploader.placepoint.no"
  config.matchingBaseUrl = "https://matching-api.placepoint.no"
  config.customerApiBaseUrl = "https://customer-api.placepoint.no"
  config.layerServerBaseUrl = "https://layers2.placepoint.no/arcgis/rest/services"
  config.layerServerBaseUrl_OLD = "https://layers.placepoint.no"
  config.pcEmbedIframeUrl = "https://app.propcloud.no/#"
}

if(config.isStaging) {
  config.socketUrl = "wss://app-geoapp-api-prod-001-staging.azurewebsites.net/graphql"
}

const envConfig = process.env.ENV_CONFIG
if(envConfig) {
  const ec = JSON.parse(envConfig)
  if(ec.CONSOLE_API_URL) config.apiUrl = ec.CONSOLE_API_URL
  if(ec.GRAPHQL_URL) config.graphqlUrl = ec.GRAPHQL_URL
  if(ec.SOCKET_URL) config.socketUrl = ec.SOCKET_URL
  if(ec.MATCHING_API_URL) config.matchingBaseUrl = ec.MATCHING_API_URL
  if(ec.FILE_UPLOADER_URL) config.fileUploaderUrl = ec.FILE_UPLOADER_URL
  if(ec.CUSTOMER_API_URL) config.customerApiBaseUrl = ec.CUSTOMER_API_URL
  if(ec.PC_EMBED_IFRAME_URL) config.pcEmbedIframeUrl = ec.PC_EMBED_IFRAME_URL
}

export default config
