import type { ServerError } from "@apollo/client"
import { fromPromise } from "@apollo/client"
import { captureException, withScope } from "@sentry/react"
import { type ErrorResponse, onError } from "@apollo/client/link/error"
import { onTokenExpired } from "@/v1-console/auth/auth.instance"
import { getIsAuthServerError } from "@/v1-console/apollo/apollo.utils"
import config from "@/v1-console/config"

function report(props: ErrorResponse) {
  const { networkError, graphQLErrors, operation } = props

  if(networkError && config.isInstrumentationEnabled) {
    captureException(networkError)
  }

  if(!graphQLErrors) return

  for(const _error of graphQLErrors) {
    const error: any = _error
    withScope((scope) => {
      const { operationName, variables, extensions } = operation

      const ctx: Record<string, unknown> = { operationName }
      if(variables) ctx.variables = JSON.stringify(variables)
      if(extensions) ctx.extensions = JSON.stringify(extensions)
      if(error.path) ctx.path = error.path.join(" > ")
      if(error.source) ctx.source = JSON.stringify(error.source)
      if(error.extensions?.code) ctx.errorCode = error.extensions.code
      if(error.extensions?.stacktrace) ctx.stacktrace = error.extensions.stacktrace

      const fingerprint = [ "graphql", operationName ]

      if(error.originalError) {
        fingerprint.push(error.originalError.constructor.name)
      }

      scope.setContext("GraphQL", ctx)
      scope.setTag("graphql.operationName", operationName)
      scope.setTransactionName(operationName)
      scope.setFingerprint(fingerprint)

      captureException(error.originalError
        ? error.originalError
        : new Error(error.message)
      , scope)
    })
  }
}

export const errorLink = onError((error) => {
  const { networkError, graphQLErrors, operation, forward } = error

  if(networkError && getIsAuthServerError(networkError as ServerError)) {
    return fromPromise(onTokenExpired())
      .flatMap(() => forward(operation))
  }

  if(config.isInstrumentationEnabled) {
    report(error)
  } else {
    // eslint-disable-next-line no-console
    if(networkError) console.error("networkError", networkError)
    // eslint-disable-next-line no-console
    if(graphQLErrors) console.error(graphQLErrors)
  }
})
