import type { ComponentProps } from "react"
import cn from "@/v1-ui/utils/utils.cn"

export type FrameBodyProps = ComponentProps<"div">

function FrameBody(props: FrameBodyProps) {
  const { className, ...divProps } = props
  return (
    <div
      className={cn("frame-body", className)}
      {...divProps}
    />
  )
}

export default FrameBody
