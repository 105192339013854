import "./package.css"

import Package from "./Package"

import PackageBadge from "./PackageBadge"
import PackageInfo from "./PackageInfo"

import PackagePrompt from "./PackagePrompt"
import PackagePromptWithOverlay from "./PackagePromptWithOverlay"
import PackagePromptText from "./PackagePromptText"

import * as constants from "./package.constants"

export type { PackageBadgeProps } from "./PackageBadge"
export type { PackageInfoProps } from "./PackageInfo"

export type { PackagePromptProps } from "./PackagePrompt"
export type { PackagePromptWithOverlayProps } from "./PackagePromptWithOverlay"
export type { PackagePromptTextProps } from "./PackagePromptText"

export default Object.assign(Package, {
  Badge: PackageBadge,
  Info: PackageInfo,

  Prompt: PackagePrompt,
  PromptWithOverlay: PackagePromptWithOverlay,
  PromptText: PackagePromptText,

  constants
})
