import type { ComponentProps } from "react"
import cn from "@/v1-ui/utils/utils.cn"
import { useFrameContext } from "@/v1-ui/Frame/frame.context"

export const DEFAULT_WIDTH = 400

export type FrameContainerProps = ComponentProps<"div"> & {
  height?: number | string,
  /** default: 400 */
  width?: number | string,
  /** default: 70 */
  minimizedWidth?: number | string
}

function FrameContainer(props: FrameContainerProps) {
  const {
    height,
    width = 400,
    minimizedWidth = 70,
    style: _style,
    className,
    ...divProps
  } = props

  const { isShown, isMinimize, isFullscreen } = useFrameContext()
  const style = { width, height }

  if(isMinimize) {
    style.width = minimizedWidth
  }

  if(isFullscreen) {
    delete style.height
    delete style.width
  }

  return (
    <div
      style={{ ...style, ..._style }}
      aria-hidden={!isShown}
      className={cn("frame-container", className, {
        "is-shown": isShown,
        "is-fullscreen": isFullscreen,
        "is-minimize": isMinimize
      })}
      {...divProps}
    />
  )
}

export default FrameContainer
