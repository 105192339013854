import { lazy } from "react"
import { lazyRetry } from "@/v1-console/router/router.utils"
import config from "@/v1-console/config"

const HubspotChat = lazy(() => lazyRetry(() => import("./HubspotChat")))

function HubspotChatSuspender() {
  if(config.isProd || config.isStaging) {
    return <HubspotChat />
  }

  return null
}

export default HubspotChatSuspender
