import { useMutation, type MutationHookOptions } from "@apollo/client"
import mutation from "./currentUser.tenant.mutation.gql"
import type {
  CurrentUserTenantMutation,
  CurrentUserTenantMutationVariables
} from "./__types__/currentUser.tenant.mutation"

type Options = MutationHookOptions<
  CurrentUserTenantMutation,
  CurrentUserTenantMutationVariables
>

function useCurrentUserTenantMutation(options?: Options) {
  return useMutation(mutation, options)
}

export default useCurrentUserTenantMutation
