import { ApolloLink, concat } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { uncrunch, type CrunchedData } from "graphql-crunch"
import config from "@/v1-console/config"

const dedupeContextLink = setContext((_op, ctx) => {
  ctx.uri = config.graphqlUrl + "?dedupe=1"
  return ctx
})

const dedupeInflateLink = new ApolloLink((op, forward) => {
  return forward(op).map((res) => {
    if(res.data) {
      res.data = uncrunch(res.data as CrunchedData) as CrunchedData
    }
    return res
  })
})

export const dedupeLink = concat(
  dedupeContextLink,
  dedupeInflateLink
)
