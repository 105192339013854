import cn from "@/v1-ui/utils/utils.cn"
import Frame, { type FrameFooterProps } from "@/v1-ui/Frame"

export type Dialog2FooterProps = FrameFooterProps

function Dialog2Footer(props: Dialog2FooterProps) {
  const { className, ...frameProps } = props
  return (
    <Frame.Footer
      className={cn("dialog2-footer", className)}
      {...frameProps}
    />
  )
}

export default Dialog2Footer
