import type { FieldPolicy } from "@apollo/client"

export const getOccupancyById: FieldPolicy = {
  read(_, { args, toReference }) {
    return toReference({
      __typename: "Occupancy",
      Id: args.input.id
    })
  }
}
