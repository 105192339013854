import { useEffect } from "react"

function AppReadyIndicator() {
  useEffect(() => {
    window.appReady = true
  }, [])
  return null
}

export default AppReadyIndicator
