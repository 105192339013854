import cn from "@/v1-ui/utils/utils.cn"
import { TooltipButton, type TooltipButtonProps } from "@/v1-ui/Button"
import { useFrameContext } from "@/v1-ui/Frame/frame.context"

export type Dialog2CloseButtonProps = TooltipButtonProps

function Dialog2CloseButton(props: Dialog2CloseButtonProps) {
  const { className, onClick, ...tooltipButtonProps } = props
  const { onHide } = useFrameContext()
  return (
    <TooltipButton
      containerClassName={cn("dialog2-close-button", className)}
      popoverClassName="z-20"
      iconSrc="cross"
      content="Lukk (esc)"
      onClick={(e) => {
        if(onClick) onClick(e)
        onHide()
      }}
      {...tooltipButtonProps}
    />
  )
}

export default Dialog2CloseButton
