export type UserNameInput = {
  FirstName?: string,
  LastName?: string,
  DisplayName?: string
}

export function getUserFullName(
  input?: UserNameInput,
  fallback = "Ukjent bruker"
) {
  let str = fallback
  if(!input) return str
  const { DisplayName, FirstName, LastName } = input
  if(DisplayName) return DisplayName
  if(FirstName) str = `${FirstName}`
  if(LastName) str = `${str} ${LastName}`
  return str
}

export function getUserInitials(input?: UserNameInput) {
  let str = null
  if(!input) return str
  const { FirstName, LastName, DisplayName } = input
  if(DisplayName) return DisplayName.charAt(0)
  if(FirstName) str = FirstName.charAt(0)
  if(LastName) str = `${str}${LastName.charAt(0)}`
  return str
}
