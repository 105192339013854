import "whatwg-fetch"
import type { ReactNode } from "react"
import { ApolloClient, ApolloProvider } from "@apollo/client"
import cache from "./apollo.cache"
import link from "./apollo.links"

const client = new ApolloClient({ cache, link })

export type ApolloClientProviderProps = {
  children: ReactNode
}

function ApolloClientProvider(props: ApolloClientProviderProps) {
  const { children } = props
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}

export default ApolloClientProvider
