import "./sidebar.css"

import Sidebar from "./Sidebar"
import SidebarItem from "./SidebarItem"
import SidebarBranding from "./SidebarBranding"
import SidebarSearch from "./SidebarSearch"

export type { SidebarProps } from "./Sidebar"
export type { SidebarItemProps } from "./SidebarItem"
export type { SidebarBrandingProps } from "./SidebarBranding"
export type { SidebarSearchProps } from "./SidebarSearch"

export default Object.assign(Sidebar, {
  Item: SidebarItem,
  Branding: SidebarBranding,
  Search: SidebarSearch
})
