import type { ReactNode } from "react"
import cn from "@/v1-ui/utils/utils.cn"

export type PackagePromptWithOverlayProps = {
  overlayClassName?: string,
  children?: ReactNode,
  isCentered?: boolean,
  prompt: ReactNode
}

function PackagePromptWithOverlay(props: PackagePromptWithOverlayProps) {
  const {
    children,
    overlayClassName,
    isCentered,
    prompt
  } = props

  if(isCentered) {
    return (
      <div className="relative">
        {children}
        <div className="package-prompt-overlay-container">
          <div className={cn("package-prompt-overlay", overlayClassName)} />
          {prompt}
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-2">
      {prompt}
      <div className="relative">
        {children}
        <div className={cn("package-prompt-overlay", overlayClassName)} />
      </div>
    </div>
  )
}

export default PackagePromptWithOverlay
