export type ConditionalWrapperProps = {
  renderWrapper: (children: JSX.Element) => JSX.Element,
  condition?: boolean,
  children: JSX.Element
}

function ConditionalWrapper(props: ConditionalWrapperProps) {
  const {
    condition,
    renderWrapper,
    children
  } = props

  return condition
    ? renderWrapper(children)
    : children
}

export default ConditionalWrapper
