import { ASSETS_BASE_URL } from "@/v1-ui/constants"

export const DEFAULT_COLOR = {
  r: 144,
  g: 167,
  b: 254
}

export const DEFAULT_POINT_SYMBOL = {
  type: "simple-marker",
  style: "circle",
  size: 8,
  color: DEFAULT_COLOR,
  outline: {
    color: "#000",
    width: 1
  }
} as __esri.SimpleMarkerSymbolProperties

export const DEFAULT_POLYGON_SYMBOL = {
  type: "simple-fill",
  color: { ...DEFAULT_COLOR, a: 0.2 },
  outline: {
    color: DEFAULT_COLOR,
    width: 1
  }
} as __esri.SimpleFillSymbolProperties

export const DEFAULT_POLYLINE_SYMBOL = {
  type: "simple-line",
  color: DEFAULT_COLOR,
  width: 2
} as __esri.SimpleLineSymbolProperties

export const DEFAULT_MARKER_SYMBOL = {
  type: "simple-marker",
  style: "path",
  size: 28,
  yoffset: 17,
  outline: {
    width: 0.5,
    color: [ 0, 0, 0, 0.35 ]
  },
  color: [ 120, 145, 156, 1 ],
  path: "M6.06462 24.8183L6.06459 24.8183L6.06866 24.8229C6.27028 25.0534 6.45692 25.2686 6.64356 25.4839L7.02134 25.1564L6.64356 25.4839L6.64458 25.4851C6.7444 25.6002 6.84438 25.7155 6.94664 25.8331C7.95672 27.007 8.8727 28.1042 9.53769 29.1569C10.2045 30.2124 10.5833 31.1692 10.5833 32.0625C10.5833 32.8529 11.1999 33.5 12 33.5C12.8001 33.5 13.4167 32.8529 13.4167 32.0625C13.4167 31.1692 13.7955 30.2124 14.4623 29.1569C15.1272 28.1043 16.0431 27.0073 17.0531 25.8335C19.8663 22.6014 23.5 18.4209 23.5 12.25C23.5 10.8064 23.2522 9.41536 22.7532 8.11834C21.6044 4.96941 19.1373 2.44359 16.0508 1.26571C14.7795 0.754187 13.4156 0.5 12 0.5C8.81647 0.5 5.93923 1.81457 3.86916 3.95044C1.78094 6.06725 0.5 9.00462 0.5 12.25C0.5 13.7818 0.727642 15.1787 1.10641 16.4766C2.09125 19.9039 4.16885 22.597 6.06462 24.8183Z"
} as __esri.SimpleMarkerSymbolProperties

export const DEFAULT_MARKER_ICON_SYMBOL = {
  type: "simple-marker",
  yoffset: 21,
  outline: null,
  color: [ 50, 75, 96, 1 ],
  style: "circle",
  size: 8
} as __esri.SimpleMarkerSymbolProperties

export const DEFAULT_MARKER_3D_SYMBOL = {
  type: "web-style",
  name: "Pushpin 3",
  styleName: "EsriIconsStyle",
  color: [ 120, 145, 156, 1 ],
  portal: {
    url: "https://www.arcgis.com"
  }
} as __esri.PointSymbol3DProperties

export const DEFAULT_PICTURE_MARKER_SYMBOL = {
  type: "picture-marker",
  width: 24,
  height: 38,
  yoffset: 19,
  url: `${ASSETS_BASE_URL}/images/icons/symbols/pin.svg`
} as __esri.PictureMarkerSymbolProperties

export const DEFAULT_TEXT_SYMBOL = {
  type: "text",
  color: "white",
  haloColor: "rgba(0, 0, 0, 0.8)",
  haloSize: "1px",
  lineWidth: 300,
  font: {
    size: 10
  }
} as __esri.TextSymbolProperties

export const DEFAULT_MUTED_TEXT_SYMBOL = {
  type: "text",
  color: "rgba(255, 255, 255, 0.8)",
  haloColor: "rgba(0, 0, 0, 0.8)",
  haloSize: "1px",
  lineWidth: 300,
  font: {
    size: 9
  }
} as __esri.TextSymbolProperties
