import type { BadgeProps } from "@/v1-ui/Badge"
import type { PackageInfoProps } from "@/v1-ui/Package"
import Badge from "@/v1-ui/Badge"
import cn from "@/v1-ui/utils/utils.cn"
import Package from "@/v1-ui/Package"
import { PACKAGE_CODES } from "./package.constants"

export type PackageBadgeProps = BadgeProps & PackageInfoProps

/**
 * Badge showing the user's package / tier
 */
function PackageBadge(props: PackageBadgeProps) {
  const {
    packageCode,
    packageIcon,
    isCustom,
    className,
    packageName,
    children,
    ...badgeProps
  } = props

  return (
    <Badge
      className={cn("package-badge", className, {
        "package-color-starter-text": packageCode === PACKAGE_CODES.starter,
        "package-color-standard-text": packageCode === PACKAGE_CODES.standard,
        "package-color-premium-text": packageCode === PACKAGE_CODES.premium,
        "package-color-custom-text": isCustom
      })}
      {...badgeProps}
    >
      {children
        ? null
        : <Package.Info
            packageCode={packageCode}
            packageName={packageName}
            packageIcon={packageIcon}
            isCustom={isCustom}
          />
      }
    </Badge>
  )
}

export default PackageBadge
