import { useMemo } from "react"
import cn from "@/v1-ui/utils/utils.cn"
import Button, { type ButtonProps } from "@/v1-ui/Button"
import LineLoader from "@/v1-ui/LineLoader"
import Avatar from "@/v1-console/components/Avatar"
import { getRandomColorBySeed } from "@/v1-console/utils/color.utils"
import type { CurrentUserFragment }
  from "@/v1-console/currentUser/__types__/currentUser.fragment"

export type CurrentUserTenantItem = ButtonProps & {
  tenantClassName?: string,
  userTenant: CurrentUserFragment["UserTenants"][0],
  isMini?: boolean
}

function CurrentUserTenantItem(props: CurrentUserTenantItem) {
  const {
    userTenant,
    isMini,
    loading,
    className,
    tenantClassName,
    ...buttonProps
  } = props

  const avatarStyle = useMemo(() => {
    if(!userTenant) return null
    return {
      backgroundColor: getRandomColorBySeed(userTenant.Tenant.Name)
    }
  }, [
    userTenant
  ])

  if(!userTenant) return null

  return (
    <Button
      className={cn("current-user-menu-tenant-picker-item", className)}
      iconClassName="absolute right-0"
      {...buttonProps}
    >
      <Avatar
        displaySize="sm"
        isLoading={loading}
        style={avatarStyle}
      >
        {userTenant.Tenant.Name}
      </Avatar>

      {isMini
        ? null
        : <div className="current-user-menu-tenant-picker-item-loader">
            <LineLoader
              height={12}
              className={cn("w-full", tenantClassName)}
              loading={loading}
            >
              {userTenant.Tenant.Name}
            </LineLoader>

            <LineLoader
              height={8}
              className="opacity-50 text-xs leading-none mt-0.5"
              loading={loading}
            >
              {userTenant.UserTenantType.Name}
            </LineLoader>
          </div>
      }
    </Button>
  )
}

export default CurrentUserTenantItem
