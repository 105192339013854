import type { ComponentProps } from "react"
import cn from "@/v1-ui/utils/utils.cn"

export type ActivityIndicatorProps = ComponentProps<"div"> & {
  shown?: boolean,
  theme?: "light" | "dark",
  displaySize?: "md" | "sm"
}

function ActivityIndicator(props: ActivityIndicatorProps) {
  const {
    className,
    shown = true,
    theme,
    displaySize = "md",
    children = "Laster..",
    ...divProps
  } = props

  if(!shown) return null

  const d = `activity-indicator-${displaySize}`
  const t = theme
    ? `activity-indicator-${theme}`
    : null

  return (
    <div
      className={cn("activity-indicator", t, d, className)}
      {...divProps}
    >
      <div className="activity-indicator-spinner" />
      {children}
    </div>
  )
}

export default ActivityIndicator
