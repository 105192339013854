import type { Obj, Primitive } from "@/v1-common/types"
import { $t } from "@/v1-console/i18n"
import { thousandSeparator } from "@/v1-ui/utils/utils.formatting"
import { formatDate } from "@/v1-ui/utils/utils.date"
import { FEATURE_TYPES } from "./feature.constants"
import type { Feature2Fragment }
  from "./__types__/feature2.fragment"
import type { Feature2WithValueTypeFragment }
  from "./__types__/feature2WithValueType.fragment"
import type { FeatureValueTypeFragment }
  from "./__types__/featureValueType.fragment"

export function assertFeature(feature?): feature is Feature2Fragment {
  return feature?.__typename === "Feature2"
}

export function assertFeature2Array(arr: any[]): arr is Feature2Fragment[] {
  return arr.every(assertFeature)
}

export function parseFeatureValue(feature?: Feature2Fragment) {

  if(!feature) return null

  if(feature.Value === null || feature.Value === undefined) return null
  if(feature.Unit?.Code === 6) { // Money
    return `kr ${thousandSeparator(feature.Value)}`
  }
  if(feature.Unit?.Code === 2) { // m²
    return `${feature.Value} ${feature.Unit.Abbreviation}`
  }

  if(feature.FeatureType.Name === FEATURE_TYPES.BOOLEAN) {
    return $t(feature.Value)
  }
  if(feature.FeatureType.Name === FEATURE_TYPES.DATE) {
    return formatDate(feature.Value, "YYYY.MM.DD")
  }
  if(feature.FeatureType.Name === FEATURE_TYPES.INTEGER) {
    return Number(feature.Value)
  }

  return feature.Value as Primitive
}

export function extractFeature(name: string, obj: Obj) {
  if(!name || !obj) return null
  return obj[name] as Feature2Fragment
}

type FeatureValueType = Record<string, FeatureValueTypeFragment>
export type Feature2WithValueType = Omit<Feature2WithValueTypeFragment, "ValueType"> & {
  ValueType?: FeatureValueType
}
export type Feature2WithValueTypeByApiName = Record<string, Feature2WithValueType>

export function groupFeature2WithValueTypeByApiName(
  arr: Feature2WithValueTypeFragment[]
) {
  const obj: Feature2WithValueTypeByApiName = {}

  // @note: we always want to return an object, even if the input array is empty
  if(!arr || arr.length === 0) return obj

  for(const f of arr) {
    let parsedValueTypeObj: FeatureValueType
    if(f.ValueType) {
      for(const vt of f.ValueType) {
        if(!parsedValueTypeObj) parsedValueTypeObj = {}
        parsedValueTypeObj[vt.Code] = vt
      }
    }
    obj[f.ApiName] = { ...f, ValueType: parsedValueTypeObj }
  }

  return obj
}
