import type { FieldPolicy } from "@apollo/client"

export const getPersonById: FieldPolicy = {
  read(_, { args, toReference }) {
    return toReference({
      __typename: "Person",
      Id: args.input.id
    })
  }
}
