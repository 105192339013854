import type { ComponentProps } from "react"
import cn from "@/v1-ui/utils/utils.cn"
import createRipple from "@/v1-ui/utils/utils.ripple"
import type { DisplaySize } from "@/v1-ui/constants"

export type NavItemProps = ComponentProps<"li"> & {
  isActive?: boolean,
  isDisabled?: boolean,
  /** Removes all styling related to interaction feedback */
  isPlain?: boolean,
  isRippleEnabled?: boolean,
  displaySize?: DisplaySize
}

function NavItem(props: NavItemProps) {
  const {
    isActive,
    isDisabled,
    isPlain,
    isRippleEnabled = true,
    displaySize = "md",
    className,
    onMouseDown,
    children,
    ...liProps
  } = props

  return (
    <li
      className={cn("nav-item", className, `nav-item-${displaySize}`, {
        "is-active": isActive,
        "is-disabled": isDisabled,
        "is-plain": isPlain
      })}
      onMouseDown={(e) => {
        if(onMouseDown) onMouseDown(e)
        if(isRippleEnabled) createRipple(e)
      }}
      {...liProps}
    >
      {children}
    </li>
  )
}

export default NavItem
