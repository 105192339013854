import type { FieldPolicy, TypePolicy } from "@apollo/client"

export const ReportPowerbi: TypePolicy = {
  keyFields: [ "Id", "ObjectTypeCode", "SourceId" ]
}

export const getReportPowerbi: FieldPolicy = {
  read(_, { args, toReference }) {
    const { id, objectTypeCode, sourceId } = args.input
    return toReference({
      __typename: "ReportPowerbi",
      Id: id,
      ObjectTypeCode: objectTypeCode,
      SourceId: sourceId
    })
  }
}
