import type { ReactNode } from "react"
import cn from "@/v1-ui/utils/utils.cn"
import Icon from "@/v1-ui/Icon"
import type { IconProps } from "@/v1-ui/Icon"
import NavItem from "@/v1-ui/NavItem"
import type { NavItemProps } from "@/v1-ui/NavItem"
import ConditionalWrapper from "@/v1-ui/ConditionalWrapper"
import Popover from "@/v1-ui/Popover"
import useSidebarContext from "./sidebar.context"

export type SidebarItemProps = NavItemProps & {
  isLoading?: boolean,
  iconSrc?: IconProps["src"],
  iconClassName?: IconProps["className"],
  iconSize?: IconProps["size"],
  iconStyle?: IconProps["style"],
  popoverContent?: ReactNode,
  popoverClassName?: string,
  popoverIsAlwaysEnabled?: boolean,
  isPopoverDisabled?: boolean,
  contentClassName?: string
}

function SidebarItem(props: SidebarItemProps) {
  const { isMini } = useSidebarContext()
  const {
    className,
    isLoading,
    iconSrc,
    iconClassName,
    iconStyle,
    popoverContent,
    popoverClassName,
    popoverIsAlwaysEnabled,
    isPopoverDisabled = false,
    contentClassName,
    children,
    ...sidebarItemProps
  } = props

  const content = popoverContent || children
  const condition = (isMini && !isPopoverDisabled) || popoverIsAlwaysEnabled

  return (
    <ConditionalWrapper
      condition={condition}
      renderWrapper={(navItem) => (
        <Popover
          content={content}
          placement="right"
          popoverClassName={cn("pointer-events-none", popoverClassName)}
          contentClassName={contentClassName}
        >
          {navItem}
        </Popover>
      )}
    >
      <NavItem
        className={cn("sidebar-item", className, {
          "is-mini": isMini
        })}
        {...sidebarItemProps}
      >
        {iconSrc
          ? <Icon
              src={iconSrc}
              style={iconStyle}
              loading={isLoading}
              className={cn("sidebar-item-icn", iconClassName, {
                "is-mini": isMini
              })}
            />
          : null
        }

        {isMini
          ? null
          : children
        }
      </NavItem>
    </ConditionalWrapper>
  )
}

export default SidebarItem
