import { useRef, useEffect, useCallback, useState, useMemo } from "react"
import useResizeObserver from "@react-hook/resize-observer"
import type { DebounceSettings, ThrottleSettings } from "lodash"
import deepmerge from "deepmerge"
import debounce from "lodash/debounce"
import throttle from "lodash/throttle"
import { useQuerySelector } from "@/v1-ui/utils/utils.dom"
import { useSearchParams } from "@/v1-console/router/router.utils"

type Cb = (...args: any[]) => any

export function useDebounce(cb: Cb, t = 350, options?: DebounceSettings) {
  return useRef(debounce(cb, t, options)).current
}

export function useThrottle(cb: Cb, t = 350, options?: ThrottleSettings) {
  return useRef(throttle(cb, t, options)).current
}

/**
 * Custom hook to create a debounced function that can access the latest callback.
 * This hook ensures that the debounced function always invokes the latest version
 * of the provided callback, even if the callback changes after the debounce is set up.
 * This is useful for debouncing functions that depend on changing state or props.
 *
 * @param cb - The callback function to debounce. This callback can include any logic and will
 *             capture the most recent values of variables it depends on, due to being wrapped
 *             in a ref that is updated on every render.
 * @param t - The debounce timeout in milliseconds. This is the delay before the debounced
 *            function is allowed to invoke the callback after the last call to it. Defaults to 350ms.
 * @param options - Optional debounce settings provided to lodash's debounce function. This can
 *                  include settings like `leading` and `trailing` to control the behavior of the
 *                  debounced function.
 *
 * @returns A debounced function that is stable across re-renders and updates to invoke the latest
 *          callback. Use this returned function as you would use the original function, but with
 *          debouncing applied.
 */
export function useDebounceWithReferences(cb: Cb, t = 350, options?: DebounceSettings) {
  const callbackRef = useRef(cb)
  const debouncedRef = useRef<ReturnType<typeof debounce>>(debounce(cb, t, options))

  useEffect(() => {
    // Update the ref to the latest callback on each render
    callbackRef.current = cb
  })

  useEffect(() => {
    // Initialize or update the debounced function when dependencies change
    debouncedRef.current = debounce((...args: any[]) => {
      // Call the latest callback
      callbackRef.current(...args)
    }, t, options)

    return () => {
      // Cleanup the debounced function on unmount or dependency change
      debouncedRef.current?.cancel()
    }
  }, [ t, options ]) // Recreate debounce function only if the timing or options change

  return debouncedRef.current
}

export function useDeepmerge<
  T extends Record<string, unknown>
>(source: T, target: Partial<T>) {
  return useMemo(() => {
    if(!source) return target
    if(!target) return source
    return deepmerge(source, target)
  }, [
    source,
    target
  ])
}

// if you create a new callback each render, then previous
// callback will be cancelled on render
export function useTimeout(
  callback: () => void,
  timeout = 0
) {
  const timeoutIdRef = useRef<any>()
  const cancel = useCallback(() => {
    const timeoutId = timeoutIdRef.current
    if(timeoutId) {
      timeoutIdRef.current = undefined
      clearTimeout(timeoutId)
    }
  }, [
    timeoutIdRef
  ])

  useEffect(() => {
    timeoutIdRef.current = setTimeout(callback, timeout)
    return cancel
  }, [
    timeout,
    callback,
    cancel
  ])

  return cancel
}

type Cursor = "auto"
  | "default"
  | "none"
  | "context-menu"
  | "help"
  | "pointer"
  | "progress"
  | "wait"
  | "cell"
  | "crosshair"
  | "text"
  | "vertical-text"
  | "alias"
  | "copy"
  | "move"
  | "no-drop"
  | "not-allowed"
  | "grab"
  | "grabbing"
  | "e-resize"
  | "n-resize"
  | "ne-resize"
  | "nw-resize"
  | "s-resize"
  | "se-resize"
  | "sw-resize"
  | "w-resize"
  | "ew-resize"
  | "ns-resize"
  | "nesw-resize"
  | "nwse-resize"
  | "col-resize"
  | "row-resize"
  | "all-scroll"
  | "zoom-in"
  | "zoom-out"

export function useCursor(
  isActive: boolean,
  cursor: Cursor
) {
  useEffect(() => {
    if(!isActive) return
    const canvas = document.querySelector("canvas")
    if(!canvas) return
    canvas.style.cursor = cursor
    return () => {
      canvas.style.cursor = ""
    }
  }, [
    isActive,
    cursor
  ])
}

export function useElementWidthHeight(selector: string) {
  const [ widthAndHeight, setWidthAndHeight ] = useState<number[]>([])
  const el = useQuerySelector(selector)

  // @todo: might want to reset the floatpanel if outside the parent view?
  useResizeObserver(el, (entry) => {
    const { width, height } = entry.contentRect
    setWidthAndHeight([ width, height ])
  })

  useEffect(() => {
    const { clientWidth, clientHeight } = el
    setWidthAndHeight([ clientWidth, clientHeight ])
  }, [
    el
  ])

  return widthAndHeight
}

export type ExtraParams = Record<string, string>
export type NavOptions = {
  replace?: boolean,
  state?: object
}

export function useSearchParamOpener(key: string, removeExtraOnClose = false) {
  const [ searchParams, setSearchParams ] = useSearchParams()
  const activeValue = searchParams.get(key)

  function getIsActive(value: string | number) {
    return activeValue === String(value)
  }

  function open(
    value: string | number = "1",
    extra?: ExtraParams,
    options?: NavOptions
  ) {
    setSearchParams({
      [key]: String(value),
      ...extra
    }, options)
  }

  function close(extra?: ExtraParams) {
    if(removeExtraOnClose && extra) {
      //map out extra keys and return them as null inside of searchParams object
      const extraKeys = {}
      for(const key of Object.keys(extra)) {
        extraKeys[key] = null
      }
      setSearchParams({
        [key]: null,
        ...extraKeys
      })
      return
    }

    setSearchParams({
      [key]: null,
      ...extra
    })
  }

  /**
   * Boolean returned represents the isActive state _after_
   * the value was toggled
   */
  function toggle(
    value: string | number = activeValue,
    extra?: ExtraParams,
    options?: NavOptions
  ) {
    const isActive = getIsActive(value)
    isActive
      ? close(extra)
      : open(value ?? "1", extra, options)
    return !isActive
  }

  return {
    searchParams,
    setSearchParams,
    isActive: !!activeValue,
    activeValue,
    getIsActive,
    open,
    close,
    toggle
  }
}

const DEFAULT_SCROLL_INTO_VIEW_OPTIONS: ScrollIntoViewOptions = {
  behavior: "smooth",
  block: "start"
}

export function scrollToElement(
  el: Element,
  options: ScrollIntoViewOptions
) {
  if(!el) return
  el.scrollIntoView({
    ...DEFAULT_SCROLL_INTO_VIEW_OPTIONS,
    ...options
  })
}

export function scrollToElementBySelector(
  selector: string,
  options?: ScrollIntoViewOptions
) {
  scrollToElement(document.querySelector(selector), options)
}

export function scrollToElementById(
  id: string,
  options?: ScrollIntoViewOptions
) {
  scrollToElement(document.getElementById(id), options)
}

export function useScrollToElementByIdFromSearchParam(key: string) {
  const { 0: searchParams } = useSearchParams()
  const id = searchParams.get(key)
  useEffect(() => {
    if(!id) return
    scrollToElementById(id)
  }, [
    id
  ])
}
