import type { FieldPolicy, Reference } from "@apollo/client"

export const createProjectObject: FieldPolicy = {
  merge(_, incoming: Reference[], options) {
    const { args, cache } = options
    const { objectTypeCode, sourceId, projectId } = args.input

    cache.modify({
      fields: {
        getProjectsByObject(existing: Reference[], options) {
          const { storeFieldName } = options
          const arg1 = `"objectTypeCode":${objectTypeCode}`
          const arg2 = `"sourceId":${sourceId}`
          if(storeFieldName.includes(arg1) && storeFieldName.includes(arg2)) {
            return [
              options.toReference(`Project:${projectId}`),
              ...existing
            ]
          }
          return existing
        }
      }
    })

    cache.evict({ fieldName: "getProjectObjects" })
    cache.evict({ fieldName: "getActivitiesByProjectByNextPrev" })
    cache.gc()

    return incoming
  }
}
