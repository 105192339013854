export const domParser = new DOMParser()

export function stripHtmlFormatting(str: string) {
  const parser = domParser.parseFromString(str, "text/html")
  return parser.body.textContent || ""
}

export function stripHtmlFormattingKeepLineBreaks(html: string) {
  // check if there is any word not wrapped in html tags
  const wordNotWrapped = html.match(/(\w+)(?![^<]*>|[^<>]*<\/)/g)
  const containsBR = html.includes("<br>") || html.includes("<br />")
  const containsDiv = html.includes("<div>") || html.includes("</div>")
  if(wordNotWrapped) {
    let res = html
    // replace first "<div>" with newline character
    res = res.replace(/<div>/, "\n")
    if(containsBR && containsDiv) {
      return res.replaceAll(/<br>|<br \/>|<div>|<\/div>/gi, match => {
        return match === "<br>" || match === "<br />" || match === "</div>" ? "\n" : ""
      })
    }
    if(containsDiv && !containsBR) {
      return res.replaceAll(/<div>|<\/div>/gi, match => {
        return match === "</div>" ? "\n" : ""
      })
    }
    if(containsBR && !containsDiv) {
      return res.replaceAll(/<br>|<br \/>/gi, match => {
        return match === "<br>" || match === "<br />" ? "\n" : ""
      })
    }
  }
  if(!wordNotWrapped) {
    if(containsBR && containsDiv) {
      return html.replaceAll(/<br>|<br \/>|<div>|<\/div>/gi, match => {
        return match === "<br>" || match === "<br />" || match === "</div>" ? "\n" : ""
      })
    }
    if(containsDiv && !containsBR) {
      return html.replaceAll(/<div>|<\/div>/gi, match => {
        return match === "</div>" ? "\n" : ""
      })
    }
    if(containsBR && !containsDiv) {
      return html.replaceAll(/<br>|<br \/>/gi, match => {
        return match === "<br>" || match === "<br />" ? "\n" : ""
      })
    }
  }
  return html
}

//Not removed yet because I'm still testing which function to use.
//Not sure if the one above works for all cases.
// export function stripHtmlFormattingKeepLineBreaks(html: string) {
//   if(new RegExp(/<\/?[a-z][\S\s]*>/i).test(html)) {
//     const wordNotWrapped = html.match(/(\w+)(?![^<]*>|[^<>]*<\/)/g)
//     const containsBR = html.includes("<br>") || html.includes("<br />")
//     const containsDiv = html.includes("<div>") || html.includes("</div>")
//     if(wordNotWrapped && !containsBR) {
//       let res = html
//       //replace first div with line break
//       res = res.replace(/<div>/, "\n")
//       //replace other divs with nothing
//       res = res.replace(/<div>/g, "")
//       //replace closing divs with line break
//       res = res.replace(/<\/div>/g, "\n")
//       return res
//     } else if(containsBR && !containsDiv) {
//       let res = html
//       //replace br with line break
//       res = res.replace(/<br>/g, "\n")
//       res = res.replace(/<br \/>/g, "\n")
//       return res
//     } else if(containsBR && containsDiv) {
//       let res = html
//       //remove all divs
//       res = res.replace(/<div>/g, "").replace(/<\/div>/g, "")
//       //replace br with line break
//       res = res.replace(/<br>/g, "\n").replace(/<br \/>/g, "\n")
//       return res
//     }
//   } else {
//     return html
//   }
// }

export function parseHtmlFromString(str: string) {
  return domParser.parseFromString(str, "text/html")
}

export function getIsFormElementFocused() {
  const el = document?.activeElement
  if(!el) return false
  const { tagName } = el
  if(tagName === "TEXTAREA") return true
  if(tagName === "INPUT") return true
  if(el.hasAttribute("contenteditable")) return true
  return false
}

export function randomId() {
  return Math.random().toString(36)
}

export function shiftArrayElements<T extends Array<unknown>>(
  arr: T,
  source: number,
  target: number
) {
  const nArr = [ ...arr ]
  if(target >= nArr.length) {
    let k = target - nArr.length + 1
    while(k--) {
      nArr.push(undefined)
    }
  }
  nArr.splice(target, 0, nArr.splice(source, 1)[0])
  return nArr
}

// export function hasProp<T extends object>(obj: T, key: keyof T ) {
export function hasProp(obj: any, key: string) {
  return Object.prototype.hasOwnProperty.call(obj, key) && !!obj[key]
}

export function filterByKey<T>(
  key: keyof T,
  value: unknown,
  array: T[]
) {
  return array
    ? array.filter((i) => i[key] === value)
    : []
}

export function findByKey<T>(
  key: keyof T,
  value: unknown,
  array: T[]
) {
  if(!array) return null
  return array
    ? array.find((i) => i[key] === value)
    : null
}

export function isInRange(value: number, min: number, max: number) {
  return (value - min) * (value - max) <= 0
}

export function getClosestNumber<T>(
  data: T[],
  nr: number,
  accessor?: (c: T) => number
) {
  let n = 0
  for(const num of data) {
    const v = (accessor ? accessor(num) : num) as number
    n = Math.abs(v - nr) < Math.abs(n - nr) ? v : n
  }
  return n
}

/*
* Escape exact string
*
* @param string - The string to escape.
*
* @returns A single string with all special regex characters preceded by a backslash (\),
* making them literal characters in a regex pattern.
*
* Use Case:
* Ideal for searching for an exact string within another string,
* where the exact string might contain characters that would otherwise be interpreted as special regex operators.
*
*/
export function escapeRegex(string: string) {
  return string.replaceAll(/[$()*+./?[\\\]^{|}-]/g, "\\$&")
}

/*
* Escape strings containing multiple words
*
* @param string - The string with multiple words to escape.
*
* @returns A string where each word is escaped like in `escapeRegex`,
* but the words are joined together with the pipe (|) character, which serves as the logical OR operator in regex.
*
* Use Case:
* Useful when you want to create a regex pattern that matches any one of multiple words within a string,
* especially in search functionalities where a user might input several keywords.
*/
export function escapeRegexMultiWords(string: string) {
  return string.split(/\s+/).map(word => word.replaceAll(/[$()*+./?[\\\]^{|}-]/g, "\\$&")).join("|")
}

export function assertNotNullOrUndefined(v: unknown) {
  return v !== null || v !== undefined
}

export function assertNotNullAndUndefined(v: unknown) {
  return v !== null && v !== undefined
}
