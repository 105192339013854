import { type ReactNode, useEffect } from "react"
import { createContext, useContext } from "react"
import type { AppContextQuery }
  from "./__types__/app.context.query"
import type { Feature2WithValueTypeByApiName }
  from "@/v1-console/feature/feature.utils"

export const EVENTS = new EventTarget()
export const APP_CTX_LOADED_EVENT = "APP_CONTEXT_LOADED"

export type AppContextProviderValue = {
  currentUser?: AppContextQuery["getCurrentUser"],
  events: EventTarget
  features?: Feature2WithValueTypeByApiName,
  layerServerToken?: string
}

export const DEFAULT_APP_CTX: AppContextProviderValue = {
  currentUser: null,
  events: EVENTS,
  features: {},
  layerServerToken: null
}

const AppContext = createContext<AppContextProviderValue>({
  currentUser: null,
  events: EVENTS,
  features: {},
  layerServerToken: null
})

export type AppContextProviderProps = {
  value: AppContextProviderValue,
  children: ReactNode
}

function AppContextProvider(props: AppContextProviderProps) {
  const { value, children } = props
  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}

export function useAppEvent<T>(
  eventName: string,
  cb: (e?: CustomEvent<T>) => void
) {
  const { events } = useAppContext()
  useEffect(() => {
    if(!cb || !events || !eventName) return
    events.addEventListener(eventName, cb)
    return () => {
      events.removeEventListener(eventName, cb)
    }
  }, [
    events,
    cb,
    eventName
  ])
}

export default AppContextProvider
