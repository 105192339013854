import { useEffect, useMemo } from "react"
import type { QueryHookOptions } from "@apollo/client"
import { makeVar, useReactiveVar, useQuery } from "@apollo/client"
import query from "./app.context.query.gql"
import type { AppContextQuery, AppContextQueryVariables }
  from "./__types__/app.context.query"

const appContextLoadingVar = makeVar(false)

type Options = QueryHookOptions<
  AppContextQuery,
  AppContextQueryVariables
>

function useAppContextQuery(options?: Options) {
  const res = useQuery(query, useMemo(() => ({
    ...options,
    notifyOnNetworkStatusChange: true
  }), [
    options
  ]))

  useEffect(() => {
    appContextLoadingVar(res.loading)
  }, [
    res.loading
  ])

  return res
}

export function useAppContextQueryIsLoading() {
  return useReactiveVar(appContextLoadingVar)
}

export default useAppContextQuery
