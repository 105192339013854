import { createContext, useContext } from "react"

export type FrameContextProps = {
  isShown?: boolean,
  onShow?(): void,
  onHide?(): void,
  /** default: true */
  isClosedOnOutsideClick?: boolean,
  /** default: true */
  isClosedOnEscKey?: boolean,
  isFullscreen?: boolean,
  onFullscreen?(isFullscreen: boolean): void,
  isMinimize?: boolean,
  onMinimize?(isMinimize: boolean): void
}

export const FrameContext = createContext(null)

export function useFrameContext() {
  const context = useContext<FrameContextProps>(FrameContext)
  if(!context) {
    throw new Error(
      "Frame compound components cannot be rendered outside the Frame component"
    )
  }
  return context
}
