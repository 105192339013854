import type { FieldPolicy, Reference } from "@apollo/client"
import type { ActivityStatsFragment }
  from "@/v1-console/activity/stats/__types__/activity.stats.fragment"

export const createActivityTask: FieldPolicy = {
  merge(_, incoming: Reference[], options) {
    const { args, cache } = options
    const { objectTypeCode, sourceId } = args.input

    cache.modify({
      fields: {
        getActivityStatsByObject(existing: ActivityStatsFragment, options) {
          const { storeFieldName } = options
          const arg1 = `"objectTypeCode":${objectTypeCode}`
          const arg2 = `"sourceId":${sourceId}`

          if(storeFieldName.includes(arg1) && storeFieldName.includes(arg2)) {
            const n = { ...existing }
            n.Total += 1
            n.TaskCount += 1
            // n.TaskUpcoming += 1
            return n
          }

          return existing
        }
      }
    })

    return incoming
  }
}

export const removeActivityTask: FieldPolicy = {
  merge(_, incoming: Reference[], options) {
    const { cache } = options
    // @todo: add objectTypeCode + sourceId to remove mutation, so we can
    //        update getActivityStatsByObject without evicting
    cache.evict({ fieldName: "getActivityStatsByObject" })
    cache.gc()
    return incoming
  }
}
