import type { ComponentProps } from "react"
import cn from "@/v1-ui/utils/utils.cn"
import Dialog2CloseButton from "./Dialog2CloseButton"
import Dialog2FullscreenButton from "./Dialog2FullscreenButton"

export type Dialog2HeaderActionsProps = ComponentProps<"div"> & {
  isFullscreenButtonEnabled?: boolean,
  isCloseButtonEnabled?: boolean
}

function Dialog2HeaderActions(props: Dialog2HeaderActionsProps) {
  const {
    className,
    children,
    isFullscreenButtonEnabled = true,
    isCloseButtonEnabled = true,
    ...frameHeaderProps
  } = props

  return (
    <div
      className={cn("dialog2-header-actions", className)}
      {...frameHeaderProps}
    >
      {children}

      {isFullscreenButtonEnabled
        ? <Dialog2FullscreenButton />
        : null
      }

      {isCloseButtonEnabled
        ? <Dialog2CloseButton />
        : null
      }
    </div>
  )
}

export default Dialog2HeaderActions
