import { useLayoutEffect } from "react"

export type OnClickOutsideEvents = globalThis.MouseEvent
  | TouchEvent
  | WheelEvent
  | KeyboardEvent

function getIsTargetWhitelisted(target: HTMLElement, id: string) {
  let elem = target
  while(elem) {
    if(elem.id === id) return true
    elem = elem.parentElement
  }

  return false
}

function checkWhitelist(target: HTMLElement, ids: string[]) {
  return ids.some((id) => getIsTargetWhitelisted(target, id))
}

export type UseClickOutsideProps = {
  onClick(e: OnClickOutsideEvents): void,
  isNextTick?: boolean,
  eventDirection?: "up" | "down",
  whitelistIds?: string[]
}

export function useOnClickOutside(props: UseClickOutsideProps) {
  useLayoutEffect(() => {
    if(!props) return
    const isNextTick = props.isNextTick ?? true
    const eventDirection = props.eventDirection || "up"
    const whitelistIds = props.whitelistIds || []

    const onEvent = (e: OnClickOutsideEvents) => {
      const targetElem = e.target as HTMLElement
      if(checkWhitelist(targetElem, whitelistIds)) {
        return
      }
      performClose(e)
    }

    // const pointerEvents = eventDirection === "up"
    //   ? [ "mouseup", "touchend", "wheel" ]
    //   : [ "mousedown", "touchstart", "wheel" ]
    const pointerEvents = eventDirection === "up"
      ? [ "mouseup", "touchend" ]
      : [ "mousedown", "touchstart" ]

    const keyEvent = eventDirection === "up"
      ? "keyup"
      : "keydown"

    function onKey(e: KeyboardEvent) {
      if(e.key === "Escape") {
        performClose(e)
      }
    }

    function performClose(e: OnClickOutsideEvents) {
      isNextTick
        ? setTimeout(() => { props.onClick(e) })
        : props.onClick(e)
    }

    document.addEventListener(keyEvent, onKey)
    for(const eventName of pointerEvents) {
      document.addEventListener(eventName, onEvent)
    }

    return () => {
      document.removeEventListener(keyEvent, onKey)
      for(const eventName of pointerEvents) {
        document.removeEventListener(eventName, onEvent)
      }
    }
  }, [
    props
  ])
}
