import type { ComponentProps, ReactNode } from "react"
import cn from "@/v1-ui/utils/utils.cn"
import { DEFAULT_PROMPT_TEXT, DEFAULT_PROMPT_URL }
  from "./package.constants"

export type PackagePromptTextProps = ComponentProps<"div"> & {
  text?: ReactNode,
  url?: string
}

function PackagePromptText(props: PackagePromptTextProps) {
  const {
    text,
    url = DEFAULT_PROMPT_URL,
    className,
    ...divProps
  } = props

  return (
    <div
      className={cn("package-prompt-text", className)}
      {...divProps}
    >
      {text} {DEFAULT_PROMPT_TEXT}

      <a
        href={url}
        target="_blank"
        className="package-prompt-url"
        rel="noreferrer"
      >
        {url}
      </a>
    </div>
  )
}

export default PackagePromptText
